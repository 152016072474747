import { put, takeLatest, call } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import update from 'immutability-helper';
import types from './types';
import moment from 'moment';

import * as OrganizationService from '../../../services/OrganizationService'
import * as NotificationService from '../../../services/NotificationService'
import * as FilesUploadService from '../../../services/FilesUploadService'
import * as Actions from './Actions'
import * as commmonActions from '../../common/redux/AppCommonActions'
import * as stringUtils from '../../../common/utility/stringUtils';

export function* getOrganizationDetails() {
    try {
        let organization = yield call(OrganizationService.GetOrganizationDetailsAPI);
        yield put(Actions.SetOrganizationDetails(organization));

        let license = organization.license;
        let licenseModel = {
            licenseExpired: false,
            startDate: new Date(),
            endDate: new Date(),
            projectCount: 0,
            subscriptionType: "",
            subscriptioncode: 0,
            reportsEnabled: false,
        };


        if (stringUtils.isNullOrEmpty(license)) {
            licenseModel = {
                ...licenseModel,
                licenseExpired: true,
            };
        } else {
            licenseModel = {
                ...licenseModel,
                startDate: license.startDate,
                endDate: license.endDate,
                projectCount: license.projectCount,
                subscriptionType: license.subscriptionType,
                subscriptioncode: license.subscriptioncode,
                reportsEnabled: (license.subscriptioncode & 2).toString() === '2' ? true : false,
            };

            if (moment.utc().isAfter(licenseModel.endDate)) {
                licenseModel = {
                    ...licenseModel,
                    licenseExpired: true,
                };
            }
        }
        
        yield put(Actions.setLicense(licenseModel));
        
    } catch (error) {
        commmonActions.disableLoader();
        toast.error("Error fetching organization details", 
            { autoClose: false });
    }
}

export function* PutOrganizationDetails(action) {
    try {
        let organization = action.value;
        yield put(commmonActions.enableLoader());
        // build board members model
        let boardMembers = yield* uploadAndconstructBoardModel(organization.boardMembers);
        organization = update(organization, {boardMembers: {$set: boardMembers}});

        // build photos model
        let photos = yield* uploadAndconstructPhotoModel(organization.photos);
        organization = update(organization, {photos: {$set: photos}});

        // build photos model
        let documents = yield* uploadAndconstructDocumentsModel(organization.documents);
        organization = update(organization, {documents: {$set: documents}});

        // build photos model
        let logoModel = yield* uploadAndconstructImageURLModel(organization.logoUrl);
        organization = update(organization, {logoUrl: {$set: logoModel}});

        let heroImageModel = yield* uploadAndconstructImageURLModel(organization.heroImageUrl);
        organization = update(organization, {heroImageUrl: {$set: heroImageModel}});

        let retval = yield call(OrganizationService.PutOrganizationDetailsAPI, organization);        
        yield put(Actions.SetOrganizationDetails(retval));
        
        toast.success("Organization updated");

    } catch (error) {
        yield put(commmonActions.disableLoader());
        toast.error("Error setting organization details", 
            { autoClose: false });
    }
}

function* uploadAndconstructBoardModel(boardMembers) {
    let boardMembermap = [];
    for (var element of boardMembers) {
        let payload;
        let urlvalue;
        if (element.id.value === null) {
            urlvalue = yield call(FilesUploadService.PostFileUploadAPI, element.file);
            payload = {
                url: urlvalue,
                name: element.name.value,
                role: element.role.value,
                aboutMember: element.aboutMember.value,
                facebookUrl: element.facebookUrl.value,
                twitterUrl: element.twitterUrl.value,
                linkedinUrl: element.linkedinUrl.value,
            }
        } else {
            if (element.file != null) {
                urlvalue = yield call(FilesUploadService.PostFileUploadAPI, element.file);
            } else {
                urlvalue = element.url.value;
            }
            payload = {
                id: element.id.value,
                url: urlvalue,
                name: element.name.value,
                role: element.role.value,
                aboutMember: element.aboutMember.value,
                facebookUrl: element.facebookUrl.value,
                twitterUrl: element.twitterUrl.value,
                linkedinUrl: element.linkedinUrl.value,
            }
        }
        boardMembermap.push(payload);
    };

    return boardMembermap;

}

function* uploadAndconstructPhotoModel(photos) {
    let returnMap = [];
    for (var element of photos) {
        let payload;
        if (element.id.value === null) {
            let urlvalue = yield call(FilesUploadService.PostFileUploadAPI, element.file);
            payload = {
                url: urlvalue,
                description: element.description.value,
            }
        } else {
            payload = {
                id: element.id.value,
                url: element.url.value,
                description: element.description.value,
            }
        }
        returnMap.push(payload);
    };

    return returnMap;
}

function* uploadAndconstructDocumentsModel(documents) {
    let returnMap = [];
    // testing
    for (var element of documents) {
        let payload;
        let urlvalue;
        if (element.id.value === null) {
            urlvalue = yield call(FilesUploadService.PostFileUploadAPI, element.file);
            payload = {
                url: urlvalue,
                filename: element.filename.value,
                type: element.type.value,
                remarks: element.remarks.value,
            }
        } else {
            if (element.file != null) {
                urlvalue = yield call(FilesUploadService.PostFileUploadAPI, element.file);
            } else {
                urlvalue = element.url.value;
            }
            payload = {
                id: element.id.value,
                url: urlvalue,
                filename: element.filename.value,
                type: element.type.value,
                remarks: element.remarks.value,
            }
        }
        returnMap.push(payload);
    };

    return returnMap;
}


function* uploadAndconstructImageURLModel(logoURLModel) {
    let retval = logoURLModel.url.value;
    if (stringUtils.isNullOrEmpty(logoURLModel.url.value) && logoURLModel.file !== null) {
        retval = yield call(FilesUploadService.PostFileUploadAPI, logoURLModel.file);
    }

    return retval;
}


export function* getCorporateTypes() {
    try {
        let types = yield call(OrganizationService.GetCorporateTypesAPI);
        yield put(Actions.setCoroprateTypes(types));

    } catch (error) {
        toast.error("Error fetching information from the server", 
            { autoClose: false });
    }
}

export function* getNGOTypes() {
    try {
        let types = yield call(OrganizationService.GetNGOTypesAPI);
        yield put(Actions.setNGOTypes(types));

    } catch (error) {
        toast.error("Error fetching information from the server", 
            { autoClose: false });
    }
}

export function* getNotifications() {
    try {
        let notifications = yield call(NotificationService.GetNotificationsAPI);
        yield put(Actions.setNotifications(notifications));

    } catch (error) {
        toast.error("Error fetching notifications from the server", 
            { autoClose: false });
    }
}

export function* readNotifications(input) {
    try {
        
        let notification = yield call(NotificationService.readNotificationAPI, input.value);        
        yield put(Actions.markNotificationReadStore(notification));

    } catch (error) {
        toast.error("Error updating notification", 
            { autoClose: false });
    }
}

export function* deleteNotifications(input) {
    try {
        yield call(NotificationService.deleteNotificationAPI, input.value);
        yield put(Actions.deleteNotificationStore(input.value));

    } catch (error) {
        toast.error("Error deleting notification", 
            { autoClose: false });
    }
}


export const OrganizationOperations = [
    takeLatest(types.FETCH_ORGANIZATION_DETAILS, getOrganizationDetails),
    takeLatest(types.PUT_ORGANIZATION_DETAILS, PutOrganizationDetails),
    takeLatest(types.FETCH_CORP_TYPES, getCorporateTypes),
    takeLatest(types.FETCH_NGO_TYPES, getNGOTypes),
    // takeLatest(types.FETCH_ORGANIZATION_DOCUMENTS, getOrganizationDocuments),
    // takeLatest(types.UPLOAD_DOCUMENT, uploadDocument),
    takeLatest(types.FETCH_NOTIFICATIONS, getNotifications),
    takeLatest(types.MARK_NOTIFICATION_READ, readNotifications),
    takeLatest(types.DELETE_NOTIFICATION, deleteNotifications),
]
