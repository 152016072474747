import React from 'react';
import LoadingOverlay from 'react-loading-overlay';
import PuffLoader from 'react-spinners/PuffLoader'

export default function MyLoader({ active, children }) {

    return (
        <LoadingOverlay
            active={active}
            spinner={<PuffLoader />}
            classNamePrefix='MyLoader_'
        >
            {children}
        </LoadingOverlay>
    )
}