// import { LOGIN_ACTION } from '../actions/AppCommon';
import types from './types'

const INITIAL_STATE = {
    signInUser: {},
};

const loginReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        // case types.AUTHENTICATE_USER:
        //     let newState = {
        //         ...state,
        //         signInUser: action.value
        //     }
        //     return newState;
        // case types.REGISTER_USER:
        //     let newState = {
        //         ...state,
        //         signInUser: action.value
        //     }
        //     return newState;
        // case types.SET_ORGANIZATION_ID:
        //     let neworgState = {
        //         ...state,
        //         isLoggedIn: true,
        //         orgId : action.value.orgId,
        //         sessionId: action.value.sessionId
        //     }
        //     return neworgState;
        case types.LOGOUT_ACTION:
            let logoutState = {
                ...state,
                signInUser: {},
            }
            return logoutState;

        default:
            return state;
    };
};
export default loginReducer;