const GET_ORGANIZATION_PUBLIC_INFO = "GET_ORGANIZATION_PUBLIC_INFO";
const SET_ORGANIZATION_PUBLIC_INFO = "SET_ORGANIZATION_PUBLIC_INFO";
const GET_ACTIVE_PUBLIC_PROJECTS = "GET_ACTIVE_PUBLIC_PROJECTS";
const SET_ACTIVE_PUBLIC_PROJECTS = "SET_ACTIVE_PUBLIC_PROJECTS";
const RESET_ACTIVE_PUBLIC_PROJECTS = "RESET_ACTIVE_PUBLIC_PROJECTS";
const GET_COMPLETE_PUBLIC_PROJECTS = "GET_COMPLETE_PUBLIC_PROJECTS";
const SET_COMPLETE_PUBLIC_PROJECTS = "SET_COMPLETE_PUBLIC_PROJECTS";
const RESET_COMPLETE_PUBLIC_PROJECTS = "RESET_COMPLETE_PUBLIC_PROJECTS";
const GET_ORGANIZATION_MEDIA = "GET_ORGANIZATION_MEDIA";
const SET_ORGANIZATION_MEDIA = "SET_ORGANIZATION_MEDIA";
const RESET_ORGANIZATION_MEDIA = "RESET_ORGANIZATION_MEDIA";
const GET_ORGANIZATION_TEAM = "GET_ORGANIZATION_TEAM";
const SET_ORGANIZATION_TEAM = "SET_ORGANIZATION_TEAM";
const RESET_ORGANIZATION_TEAM = "RESET_ORGANIZATION_TEAM";


const types = {
    GET_ORGANIZATION_PUBLIC_INFO,
    SET_ORGANIZATION_PUBLIC_INFO,
    GET_ACTIVE_PUBLIC_PROJECTS,
    SET_ACTIVE_PUBLIC_PROJECTS,
    RESET_ACTIVE_PUBLIC_PROJECTS,
    GET_COMPLETE_PUBLIC_PROJECTS,
    SET_COMPLETE_PUBLIC_PROJECTS,
    RESET_COMPLETE_PUBLIC_PROJECTS,
    GET_ORGANIZATION_MEDIA,
    SET_ORGANIZATION_MEDIA,
    RESET_ORGANIZATION_MEDIA,
    GET_ORGANIZATION_TEAM,
    SET_ORGANIZATION_TEAM,
    RESET_ORGANIZATION_TEAM,

    };

export default types;