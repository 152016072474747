import http from './http';
import config from '../common/constants/AppConstants'
import * as httpCommon from './httpCommon'
import qs from 'qs'


export const GetProjectsAPI = async (typefilter) => {
    let apiUrl = `${config.service.serverUri}${config.service.project.uri}`
    return new Promise((resolve, reject) => {
        http.get(apiUrl, { params: { type: typefilter } })
            .then(function (response) {
                if (httpCommon.getResponseStatus(response)) {
                    try {
                        resolve(response.data);
                    }
                    catch (error) {
                        console.log(error);
                    }
                }
                else {
                    reject(response.data);
                }
            })
            .catch(function (error) {
                reject(error.response);
            });
    });
}

export const createProjectsAPI = async (project) => {
    let apiUrl = `${config.service.serverUri}${config.service.project.ops}`
    let payloadData = project;
    return new Promise((resolve, reject) => {
        http.post(apiUrl, payloadData)
            .then(function (response) {
                if (httpCommon.getResponseStatus(response)) {
                    try {
                        resolve(response.data);
                    }
                    catch (error) {
                        console.log(error);
                    }
                }
                else {
                    reject(response.data);
                }
            })
            .catch(function (error) {
                reject(error.response);
            });
    });
}

export const updateProjectAPI = async (project) => {
    let apiUrl = `${config.service.serverUri}${config.service.project.ops}` + `/` + project.id;
    let payloadData = project;
    return new Promise((resolve, reject) => {
        http.put(apiUrl, payloadData)
            .then(function (response) {
                if (httpCommon.getResponseStatus(response)) {
                    try {
                        resolve(response.data);
                    }
                    catch (error) {
                        console.log(error);
                    }
                }
                else {
                    reject(response.data);
                }
            })
            .catch(function (error) {
                reject(error.response);
            });
    });
}


export const deleteProjectsAPI = async (projectid) => {
    let apiUrl = `${config.service.serverUri}${config.service.project.ops}` + `/` + projectid;
    return new Promise((resolve, reject) => {
        http.delete(apiUrl)
            .then(function (response) {
                if (httpCommon.getResponseStatus(response)) {
                    try {
                        resolve(response.data);
                    }
                    catch (error) {
                        console.log(error);
                    }
                }
                else {
                    reject(response.data);
                }
            })
            .catch(function (error) {
                reject(error.response);
            });
    });
}

export const fetchProjectDetailsAPI = async (projectid) => {
    let apiUrl = `${config.service.serverUri}${config.service.project.ops}` + `/` + projectid;
    return new Promise((resolve, reject) => {
        http.get(apiUrl)
            .then(function (response) {
                if (httpCommon.getResponseStatus(response)) {
                    try {
                        resolve(response.data);
                    }
                    catch (error) {
                        console.log(error);
                    }
                }
                else {
                    reject(response.data);
                }
            })
            .catch(function (error) {
                reject(error.response);
            });
    });
}

export const getProjectInfoDetailedAPI = async (projectid) => {
    let apiUrl = `${config.service.serverUri}${config.service.project.ops}` + `/` + projectid;
    return new Promise((resolve, reject) => {
        http.get(apiUrl, { params: { detailed: true }})
            .then(function (response) {
                if (httpCommon.getResponseStatus(response)) {
                    try {
                        resolve(response.data);
                    }
                    catch (error) {
                        console.log(error);
                    }
                }
                else {
                    reject(response.data);
                }
            })
            .catch(function (error) {
                reject(error.response);
            });
    });
}

export const getProjectTasksAPI = async (projectId) => {
    let apiUrl = `${config.service.serverUri}${config.service.project.ops}` + `/` + projectId + `/` + `${config.service.project.tasks}`;
    return new Promise((resolve, reject) => {
        http.get(apiUrl)
            .then(function (response) {
                if (httpCommon.getResponseStatus(response)) {
                    try {
                        resolve(response.data);
                    }
                    catch (error) {
                        console.log(error);
                    }
                }
                else {
                    reject(response.data);
                }
            })
            .catch(function (error) {
                reject(error.response);
            });
    });
}

export const updateProjectTasks = async (input) => {
    let apiUrl = `${config.service.serverUri}${config.service.project.ops}` + `/` + input.projectid + `/` + `${config.service.project.tasks}`;
    let payloadData = input.data;
    return new Promise((resolve, reject) => {
        http.post(apiUrl, payloadData)
            .then(function (response) {
                if (httpCommon.getResponseStatus(response)) {
                    try {
                        resolve(response.data);
                    }
                    catch (error) {
                        console.log(error);
                    }
                }
                else {
                    reject(response.data);
                }
            })
            .catch(function (error) {
                reject(error.response);
            });
    });
}

export const SearchProjectsAPI = async (searchParams) => {
    let apiUrl = `${config.service.serverUri}${config.service.project.uri}/${config.service.project.search}`

    let jsonstring = JSON.stringify(searchParams);
    return new Promise((resolve, reject) => {
        http.get(apiUrl, { params: { filter: jsonstring } })
            .then(function (response) {
                if (httpCommon.getResponseStatus(response)) {
                    try {
                        resolve(response.data);
                    }
                    catch (error) {
                        console.log(error);
                    }
                }
                else {
                    reject(response.data);
                }
            })
            .catch(function (error) {
                reject(error.response);
            });
    });
}

export const getProjectReportsAPI = async (projectid) => {
    let apiUrl = `${config.service.serverUri}${config.service.project.ops}/${projectid}/${config.service.project.reports}`

    return new Promise((resolve, reject) => {
        http.get(apiUrl)
            .then(function (response) {
                if (httpCommon.getResponseStatus(response)) {
                    try {
                        resolve(response.data);
                    }
                    catch (error) {
                        console.log(error);
                    }
                }
                else {
                    reject(response.data);
                }
            })
            .catch(function (error) {
                reject(error.response);
            });
    });
}

export const getSchedulesByProjectAPI = async (projectid) => {
    let apiUrl = `${config.service.serverUri}${config.service.project.ops}/${projectid}/${config.service.project.schedules}`

    return new Promise((resolve, reject) => {
        http.get(apiUrl)
            .then(function (response) {
                if (httpCommon.getResponseStatus(response)) {
                    try {
                        resolve(response.data);
                    }
                    catch (error) {
                        console.log(error);
                    }
                }
                else {
                    reject(response.data);
                }
            })
            .catch(function (error) {
                reject(error.response);
            });
    });
}


export const postScheduleByProjectAPI = async (input) => {
    let apiUrl = `${config.service.serverUri}${config.service.project.ops}/${input.projectid}/${config.service.project.schedules}`
    let payloadData = input.schedule;    
    return new Promise((resolve, reject) => {
        http.post(apiUrl, payloadData)
            .then(function (response) {
                if (httpCommon.getResponseStatus(response)) {
                    try {
                        resolve(response.data);
                    }
                    catch (error) {
                        console.log(error);
                    }
                }
                else {
                    reject(response.data);
                }
            })
            .catch(function (error) {
                reject(error.response);
            });
    });
}

export const putScheduleByProjectAPI = async (input) => {
    let apiUrl = `${config.service.serverUri}${config.service.project.ops}/${input.projectid}/${config.service.project.schedules}/${config.service.project.schedule}/${input.schedule.id}`
    let payloadData = input.schedule;
    return new Promise((resolve, reject) => {
        http.put(apiUrl, payloadData)
            .then(function (response) {
                if (httpCommon.getResponseStatus(response)) {
                    try {
                        resolve(response.data);
                    }
                    catch (error) {
                        console.log(error);
                    }
                }
                else {
                    reject(response.data);
                }
            })
            .catch(function (error) {
                reject(error.response);
            });
    });
}


export const deleteScheduleByProjectAPI = async (input) => {
    let apiUrl = `${config.service.serverUri}${config.service.project.ops}/${input.projectid}/${config.service.project.schedules}/${config.service.project.schedule}/${input.scheduleid}`
    return new Promise((resolve, reject) => {
        http.delete(apiUrl)
            .then(function (response) {
                if (httpCommon.getResponseStatus(response)) {
                    try {
                        resolve(response.data);
                    }
                    catch (error) {
                        console.log(error);
                    }
                }
                else {
                    reject(response.data);
                }
            })
            .catch(function (error) {
                reject(error.response);
            });
    });
}
