import React from 'react';
// import Logo from 'images/logo-main.png';
import logo from '../images/logo-main.png'

export default function CSRLoader() {
    return (
        <div className="csr-loader-container">
            {/* <img src={logo} alt="CSRNexus Logo" /> */}
            <br />
            <div className="basic-loader" />
        </div>
    )
}

// .csr-loader-container {
//     height: 100vh;
//     width: 100%;
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;
//     text-align: center;
//     background: #fff;
//     margin: 0;
//     padding: 0;
// }
