import http from './http';
import config from '../common/constants/AppConstants'

import * as httpCommon from './httpCommon'


export const GetNotificationsAPI = async () => {
    let apiUrl = `${config.service.serverUri}${config.service.notifications.uri}`;
    return new Promise((resolve, reject) => {
        http.get(apiUrl)
            .then(function (response) {
                if (httpCommon.getResponseStatus(response)) {
                    try {
                        resolve(response.data);
                    }
                    catch (error) {
                        console.log(error);
                    }
                }
                else {
                    reject(response.data);
                }
            })
            .catch(function (error) {
                reject(error.response);
            });
    });
}

export const GetNotificationDetailsAPI = async (notificationid) => {
    let apiUrl = `${config.service.serverUri}${config.service.notifications.uri}${config.service.notifications.notification}` + `/` + notificationid;
    return new Promise((resolve, reject) => {
        http.get(apiUrl)
            .then(function (response) {
                if (httpCommon.getResponseStatus(response)) {
                    try {
                        resolve(response.data);
                    }
                    catch (error) {
                        console.log(error);
                    }
                }
                else {
                    reject(response.data);
                }
            })
            .catch(function (error) {
                reject(error.response);
            });
    });
}

export const readNotificationAPI = async (notificationid) => {
    let apiUrl = `${config.service.serverUri}${config.service.notifications.uri}` + `/` + `${config.service.notifications.notification}` + `/` + notificationid + `/read`;
    return new Promise((resolve, reject) => {
        http.put(apiUrl)
            .then(function (response) {
                if (httpCommon.getResponseStatus(response)) {
                    try {
                        resolve(response.data);
                    }
                    catch (error) {
                        console.log(error);
                    }
                }
                else {
                    reject(response.data);
                }
            })
            .catch(function (error) {
                reject(error.response);
            });
    });
}

export const deleteNotificationAPI = async (notificationid) => {
    let apiUrl = `${config.service.serverUri}${config.service.notifications.uri}` + `/` + `${config.service.notifications.notification}` + `/` + notificationid;
    return new Promise((resolve, reject) => {
        http.delete(apiUrl)
            .then(function (response) {
                if (httpCommon.getResponseStatus(response)) {
                    try {
                        resolve(response.data);
                    }
                    catch (error) {
                        console.log(error);
                    }
                }
                else {
                    reject(response.data);
                }
            })
            .catch(function (error) {
                reject(error.response);
            });
    });
}