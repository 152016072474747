import types from './types';

export const SetOwnerProjectsSummary = (value) => {

    return {
        type: types.SET_OWNER_PROJECTS_SUMMARY,
        value: value
    }
};

export const resetOwnerProjectsSummary = (value) => {

    return {
        type: types.RESET_OWNER_PROJECTS_SUMMARY,
        value: value
    }
};

export const FetchOwnerProjectsSummary = (value) => {

    return {
        type: types.FETCH_OWNER_PROJECTS_SUMMARY,
        value: value
    }
};

export const SetOwnInprogressProjectsSummary = (value) => {

    return {
        type: types.SET_OWNER_INPROGRESS_PROJECTS_SUMMARY,
        value: value
    }
};

export const FetchOwnerInprogressProjectsSummary = (value) => {

    return {
        type: types.FETCH_OWNER_INPROGRESS_PROJECTS_SUMMARY,
        value: value
    }
};

export const SetOwnCompleteProjectsSummary = (value) => {

    return {
        type: types.SET_OWNER_COMPLETE_PROJECTS_SUMMARY,
        value: value
    }
};

export const FetchOwnerCompleteProjectsSummary = (value) => {

    return {
        type: types.FETCH_OWNER_COMPLETE_PROJECTS_SUMMARY,
        value: value
    }
};

export const SetInvitedProjectsSummary = (value) => {

    return {
        type: types.SET_INVITED_PROJECTS_SUMMARY,
        value: value
    }
};

export const ResetInvitedProjectsSummary = (value) => {

    return {
        type: types.RESET_INVITED_PROJECTS_SUMMARY,
        value: value
    }
};


export const FetchInvitedProjectsSummary = (value) => {

    return {
        type: types.FETCH_INVITED_PROJECTS_SUMMARY,
        value: value
    }
};

export const PutInvitedProjectResponse = (value) => {

    return {
        type: types.PUT_INVITATION_RESPONSE,
        value: value
    }
};

export const SetFocusAreas = (value) => {

    return {
        type: types.SET_FOCUS_AREAS,
        value: value
    }
};

export const FetchFocusAreas = (value) => {

    return {
        type: types.FETCH_FOCUS_AREAS,
        value: value
    }
};

export const createProject = (value) => {

    return {
        type: types.PROJECT_CREATE,
        value: value
    }
};

export const editProject = (value) => {

    return {
        type: types.PROJECT_EDIT,
        value: value
    }
};

export const deleteProject = (value) => {

    return {
        type: types.PROJECT_DELETE,
        value: value
    }
};

export const resetStore = (value) => {
    return {
        type: types.RESET_STORE,
        value: value
    }    
}

// export const fetchProjectDetails = (value) => {

//     return {
//         type: types.FETCH_PROJECT_DETAILS,
//         value: value
//     }
// };

// export const setProjectDetails = (value) => {

//     return {
//         type: types.SET_PROJECT_DETAILS,
//         value: value
//     }
// };

export const fetchProjectTasks = (value) => {

    return {
        type: types.FETCH_PROJECT_TASKS,
        value: value
    }
};

export const setProjectTasks = (value) => {

    return {
        type: types.SET_PROJECT_TASKS,
        value: value
    }
};
export const postProjectTasks = (value) => {

    return {
        type: types.POST_PROJECT_TASKS,
        value: value
    }
};

export const setProjectInfoDetailed = (value) => {

    return {
        type: types.SET_PROJECT_INFO_DETAILS,
        value: value
    }
};

export const getProjectInfoDetailed = (value) => {

    return {
        type: types.FETCH_PROJECT_INFO_DETAILS,
        value: value
    }
};

export const resetProjectInfoDetailed = (value) => {

    return {
        type: types.RESET_PROJECT_INFO_DETAILS,
        value: value
    }
};

// export const postProjectPhotos = (value) => {

//     return {
//         type: types.POST_PROJECT_PHOTOS,
//         value: value
//     }
// };

// export const getProjectPhotos = (value) => {

//     return {
//         type: types.FETCH_PROJECT_PHOTOS,
//         value: value
//     }
// };

export const setProjectPhotos = (value) => {

    return {
        type: types.SET_PROJECT_PHOTOS,
        value: value
    }
};

export const fetchProjectReports = (value) => {

    return {
        type: types.FETCH_PROJECT_REPORTS,
        value: value
    }
};

export const setProjectReports = (value) => {

    return {
        type: types.SET_PROJECT_REPORTS,
        value: value
    }
};

export const resetProjectReports = (value) => {

    return {
        type: types.RESET_PROJECT_REPORTS,
        value: value
    }
};

export const fetchInvitationsByOrganization = (value) => {

    return {
        type: types.FETCH_INVITES_BY_ORGANIZATION,
        value: value
    }
};

export const setInvitationsByOrganization = (value) => {

    return {
        type: types.SET_INVITES_BY_ORGANIZATION,
        value: value
    }
};

export const resetInvitationsByOrganization = (value) => {

    return {
        type: types.RESET_INVITES_BY_ORGANIZATION,
        value: value
    }
};

export const deleteInvitation = (value) => {

    return {
        type: types.DELETE_INVITATION,
        value: value
    }
};

export const acceptInvitation = (value) => {

    return {
        type: types.ACCEPT_PROJECT_INVITE,
        value: value
    }
};

export const rejectInvitation = (value) => {

    return {
        type: types.REJECT_PROJECT_INVITE,
        value: value
    }
};

export const onholdInvitation = (value) => {

    return {
        type: types.ONHOLD_PROJECT_INVITE,
        value: value
    }
};

export const withdrawInvitation = (value) => {

    return {
        type: types.WITHDRAW_PROJECT_INVITE,
        value: value
    }
};


export const fetchProjectListForInvites = (value) => {

    return {
        type: types.FETCH_PROJECTLIST_FOR_INVITES,
        value: value
    }
};

export const setProjectListForInvites = (value) => {

    return {
        type: types.SET_PROJECTLIST_FOR_INVITES,
        value: value
    }
};

export const resetProjectListForInvites = (value) => {

    return {
        type: types.RESET_PROJECTLIST_FOR_INVITES,
        value: value
    }
};
export const fetchIncomingInvitationsByOrganization = (value) => {

    return {
        type: types.FETCH_INCOMING_INVITES_BY_ORGANIZATION,
        value: value
    }
};

export const setIncomingInvitationsByOrganization = (value) => {

    return {
        type: types.SET_INCOMING_INVITES_BY_ORGANIZATION,
        value: value
    }
};

export const resetIncomingInvitationsByOrganization = (value) => {

    return {
        type: types.RESET_INCOMING_INVITES_BY_ORGANIZATION,
        value: value
    }
};

export const fetchOutgoingInvitationsByOrganization = (value) => {

    return {
        type: types.FETCH_OUTGOING_INVITES_BY_ORGANIZATION,
        value: value
    }
};

export const setOutgoingInvitationsByOrganization = (value) => {

    return {
        type: types.SET_OUTGOING_INVITES_BY_ORGANIZATION,
        value: value
    }
};

export const resetOutgoingInvitationsByOrganization = (value) => {

    return {
        type: types.RESET_OUTGOING_INVITES_BY_ORGANIZATION,
        value: value
    }
};

export const searchOrganizations = (value) => {

    return {
        type: types.SEARCH_ORGANIZATIONS,
        value: value
    }
};

export const setSearchOrganizations = (value) => {

    return {
        type: types.SET_SEARCH_RESULT,
        value: value
    }
};

export const resetSearchOrganizations = (value) => {

    return {
        type: types.RESET_SEARCH_RESULT,
        value: value
    }
};

export const fetchSchedulesByProject = (value) => {

    return {
        type: types.FETCH_SCHEDULES_BY_PROJECT,
        value: value
    }
};

export const setScheduleForProject = (value) => {

    return {
        type: types.SET_SCHEDULE_FOR_PROJECT,
        value: value
    }
};

export const updateScheduleForProject = (value) => {

    return {
        type: types.UPDATE_SCHEDULE_FOR_PROJECT,
        value: value
    }
};

export const resetScheduleForProject = (value) => {

    return {
        type: types.RESET_SCHEDULE_FOR_PROJECT,
        value: value
    }
};

export const saveScheduleForProject = (value) => {

    return {
        type: types.SAVE_SCHEDULE_FOR_PROJECT,
        value: value
    }
};

export const deleteScheduleForProject = (value) => {

    return {
        type: types.DELETE_SCHEDULE_FOR_PROJECT,
        value: value
    }
};

export const deleteScheduleFromStore = (value) => {

    return {
        type: types.DELETE_SCHEDULE_FROM_STORE,
        value: value
    }
};


const ProjectActions = {
    SetOwnerProjectsSummary,
    resetOwnerProjectsSummary,
    FetchOwnerProjectsSummary,
    SetFocusAreas,
    FetchFocusAreas,
    createProject,
    editProject,
    deleteProject,
    fetchProjectTasks,
    setProjectTasks,
    postProjectTasks,
    setProjectInfoDetailed,
    getProjectInfoDetailed,
    resetProjectInfoDetailed,
    PutInvitedProjectResponse,
    fetchProjectReports,
    setProjectReports,
    resetProjectReports,
    fetchInvitationsByOrganization,
    setInvitationsByOrganization,
    resetInvitationsByOrganization,
    fetchIncomingInvitationsByOrganization,
    setIncomingInvitationsByOrganization,
    resetIncomingInvitationsByOrganization,
    fetchOutgoingInvitationsByOrganization,
    setOutgoingInvitationsByOrganization,
    resetOutgoingInvitationsByOrganization,    
    deleteInvitation,
    acceptInvitation,
    rejectInvitation,
    onholdInvitation,
    withdrawInvitation,
    fetchProjectListForInvites,
    setProjectListForInvites,
    resetProjectListForInvites,
    searchOrganizations,
    setSearchOrganizations,
    resetSearchOrganizations,
    fetchSchedulesByProject,
    setScheduleForProject,
    resetScheduleForProject,
    saveScheduleForProject,
    deleteScheduleForProject,
    resetStore,
};

export default ProjectActions;