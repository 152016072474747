
const devConfig = {
    springServiceBaseUrl: 'http://localhost:8081/api/',
    keycloakAuthority: 'http://localhost/auth/realms/dev',
};

const prodConfig = {
    springServiceBaseUrl: 'https://csrnexus.com/api/',
    keycloakAuthority: 'https://csrnexus.com/auth/realms/prod',
};

const config = process.env.mode === 'production'
    ? prodConfig
    : devConfig;

export default {
    ...config
};