import types from './types';

export const getOrganizationPublicInfo = (value) => {

    return {
        type: types.GET_ORGANIZATION_PUBLIC_INFO,
        value: value
    }
};

export const setOrganizationPublicInfo = (value) => {

    return {
        type: types.SET_ORGANIZATION_PUBLIC_INFO,
        value: value
    }
};

export const getActivePublicProjects = (value) => {

    return {
        type: types.GET_ACTIVE_PUBLIC_PROJECTS,
        value: value
    }
};

export const setActivePublicProjects = (value) => {

    return {
        type: types.SET_ACTIVE_PUBLIC_PROJECTS,
        value: value
    }
};


export const resetActivePublicProjects = (value) => {

    return {
        type: types.RESET_ACTIVE_PUBLIC_PROJECTS,
        value: value
    }
};

export const getCompletePublicProjects = (value) => {

    return {
        type: types.GET_COMPLETE_PUBLIC_PROJECTS,
        value: value
    }
};

export const setCompletePublicProjects = (value) => {

    return {
        type: types.SET_COMPLETE_PUBLIC_PROJECTS,
        value: value
    }
};

export const resetCompletePublicProjects = (value) => {

    return {
        type: types.RESET_COMPLETE_PUBLIC_PROJECTS,
        value: value
    }
};

export const getOrganizationMedia = (value) => {

    return {
        type: types.GET_ORGANIZATION_MEDIA,
        value: value
    }
};

export const setOrganizationMedia = (value) => {

    return {
        type: types.SET_ORGANIZATION_MEDIA,
        value: value
    }
};

export const resetOrganizationMedia = (value) => {

    return {
        type: types.RESET_ORGANIZATION_MEDIA,
        value: value
    }
};


export const getOrganizationTeam = (value) => {

    return {
        type: types.GET_ORGANIZATION_TEAM,
        value: value
    }
};

export const setOrganizationTeam = (value) => {

    return {
        type: types.SET_ORGANIZATION_TEAM,
        value: value
    }
};

export const resetOrganizationTeam = (value) => {

    return {
        type: types.RESET_ORGANIZATION_TEAM,
        value: value
    }
};


const PublicActions = {
    getOrganizationPublicInfo,
    setOrganizationPublicInfo,
    getActivePublicProjects,
    setActivePublicProjects,
    resetActivePublicProjects,
    getCompletePublicProjects,
    setCompletePublicProjects,
    resetCompletePublicProjects,
    getOrganizationMedia,
    setOrganizationMedia,
    resetOrganizationMedia,
    getOrganizationTeam,
    setOrganizationTeam,
    resetOrganizationTeam,
};

export default PublicActions;