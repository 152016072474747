// import { LOGIN_ACTION } from '../actions/AppCommon';
import types from './types'

const INITIAL_STATE = {
    organization: {
        id: "",
        name: "",
        type: "",
        registrationNo: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        country: "",
        postalCode: "",
        phone1: "",
        phone2: "",
        corpIndustrySector: "",
        ngoRegistrationType: "",
        establishmentYear: "",
        aboutUs: "",
        websiteUrl: "",
        facebookUrl: "",
        publicUrl: "",
        boardMembers: [],
        focusAreas: [],
        photos: [],
        documents: [],
        sdgs: [],
    },
    activeprojects: [],
    completeprojects: [],
    media: [],
    team: [],
};

const PublicReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.SET_ORGANIZATION_PUBLIC_INFO: {
            let newState = {
                ...state,
                organization: action.value
            }
            return newState;
        }
        case types.SET_ACTIVE_PUBLIC_PROJECTS: {
            let newState = {
                ...state,
                activeprojects: action.value
            }
            return newState;
        }
        case types.RESET_ACTIVE_PUBLIC_PROJECTS: {
            let newState = {
                ...state,
                activeprojects: []
            }
            return newState;
        }
        case types.SET_COMPLETE_PUBLIC_PROJECTS: {
            let newState = {
                ...state,
                completeprojects: action.value
            }
            return newState;
        }
        case types.RESET_COMPLETE_PUBLIC_PROJECTS: {
            let newState = {
                ...state,
                completeprojects: []
            }
            return newState;
        }
        case types.SET_ORGANIZATION_MEDIA: {
            let newState = {
                ...state,
                media: action.value
            }
            return newState;
        }
        case types.RESET_ORGANIZATION_MEDIA: {
            let newState = {
                ...state,
                media: []
            }
            return newState;
        }

        case types.SET_ORGANIZATION_TEAM: {
            let newState = {
                ...state,
                team: action.value
            }
            return newState;
        }
        case types.RESET_ORGANIZATION_TEAM: {
            let newState = {
                ...state,
                team: []
            }
            return newState;
        }


        default:
            return state;
    };
};
export default PublicReducer;