import React, { useState, useEffect } from 'react';
// import { Nav, Navbar, NavDropdown, Form, Button, Image } from 'react-bootstrap'
import update from 'immutability-helper';
// import { push } from 'connected-react-router'
import { Link } from "react-router-dom";
import * as routingConstants from './constants/routingConstants'
// import userManager from '../../common/utility/userManager'
import logomain from '../../images/logo-main.png'
// import logosocial from '../../images/logo-title.png'
import * as stringUtils from '../../common/utility/stringUtils'
// import navtoggle from '../../images/new/nav-toggle.png'

import '../../styles/_webHome.scss'


function Header(props) {

    const [authInformation, SetAuthInformation] = useState({
        userLoggedin: false,
        tokenExpired: false,
        user: {
            firstName: null,
            lastName: null,
        }
    });
    const [resetAuthInformation, setResetAuthInformation] = useState(authInformation);

    useEffect(() => {
        SetAuthInformation(props.App.authInfo);
    }, [props.App.authInfo]);


    const handleLoginClick = () => {
        if (!authInformation.userLoggedin) {
            props.history.push(routingConstants.LOGIN);
            // userManager.signinRedirect();
        } else {
            props.logoutUser();
        }
    }

    const showNotification = () => {
        props.fetchNotifications();
        props.showNotifications();
    }

    return (
        <div>
            {authInformation.userLoggedin ?
                <div>
                    <nav className="navbar navbar-expand-lg">
                        <div className="container-fluid">
                            <a className="navbar-brand" href="#home">
                                <img className="cardimgsocial img-fluid" src={stringUtils.isNullOrEmpty(props.organization) ? "" : props.organization.logoUrl} alt="Logo" />
                                {stringUtils.isNullOrEmpty(props.organization) ? "" : props.organization.name}
                                <span> <label>(Powered by <img className='cardimgNexus img-fluid' src={logomain} alt='' /> )</label></span>
                            </a>
                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className="collapse navbar-collapse" id="navbarNav">
                                <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                                    <li className="nav-item">
                                        <Link className="nav-link" to={routingConstants.LOGIN_HOME}>Home</Link>
                                    </li>
                                    <li className="nav-item dropdown">
                                        <Link className="nav-link dropdown-toggle" role="button" to="" data-bs-toggle="dropdown" aria-expanded="false">Projects</Link>
                                        <ul className="dropdown-menu">
                                            <li><Link className="dropdown-item nav-link" to={routingConstants.PROJECTS_OWN}>Projects</Link></li>
                                            <li><hr className="dropdown-divider" /></li>
                                            <li><Link className={`{dropdown-item nav-link ${props.license.licenseExpired ? 'disabled' : null}`} to={routingConstants.MANAGE_INCOMING_PROJECT_INVITES}>Incoming Invites</Link></li>
                                            <li><Link className={`dropdown-item nav-link ${props.license.licenseExpired ? 'disabled' : null}`} to={routingConstants.MANAGE_OUTGOING_PROJECT_INVITES}>Outgoing Invites</Link></li>
                                        </ul>
                                    </li>
                                    <li className="nav-item">
                                        <Link className={`nav-link ${props.license.licenseExpired ? 'disabled' : null}`} to={routingConstants.REPORTS_OWN}>Reports</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to={routingConstants.ORGANIZATION_PROFILE}>Profile</Link>
                                    </li>
                                    <li className="nav-item dropdown">
                                        <Link className="nav-link dropdown-toggle bi bi-person" to="" role="button" data-bs-toggle="dropdown" aria-expanded="false">{authInformation.user.firstName}</Link>
                                        <ul className="dropdown-menu dropdown-menu-end">
                                            <li><button className={`dropdown-item nav-link`} onClick={showNotification}>Notifications</button></li>
                                            {/* <li><Link className="dropdown-item nav-link" onClick={props.showNotifications}>Notifications</Link></li> */}
                                            <li><Link className={`dropdown-item nav-link`} to="" onClick={handleLoginClick}>Logout</Link></li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
                :
                <div>
                    <nav className="navbar navbar-expand-lg" role='navigation'>
                        <div className="container-fluid">
                            <a className="navbar-brand" href="/">
                                <img src={logomain} alt="Logo" className="d-inline-block align-text-top" />
                            </a>
                            <button className="navbar-toggler " type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className="collapse navbar-collapse collapsed" id="navbarNav">
                                <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                                    <li className="nav-item">
                                        <a className="nav-link" href="#Offerings">Offerings</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#projects">Projects</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href={routingConstants.PRICING} target='_blank'>Pricing</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#aboutussection">About Us</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#mentors">Our Mentors</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#ourteam">Our Team</a>
                                    </li>
                                    <form className="d-flex">
                                        <button className="nexusButton btn btn-primary" type="submit" onClick={handleLoginClick}>Login</button>
                                    </form>

                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
            }

        </div>
    );

}
export default Header;