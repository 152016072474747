const FETCH_PROJECTS = "FETCH_PROJECTS";
const SET_PROJECTS = "SET_PROJECTS";
const RESET_PROJECTS = "RESET_PROJECTS";
const CREATE_INVITE = "CREATE_INVITE";
// const UPDATE_INVITE = "UPDATE_INVITE";
const RESET_STORE = "RESET_STORE";

const types = {
    FETCH_PROJECTS,
    SET_PROJECTS,
    RESET_PROJECTS,
    CREATE_INVITE,
    // UPDATE_INVITE,
    RESET_STORE,
};

export default types;