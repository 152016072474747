// const SET_USER_IS_AUTHORIZED = "SET_USER_IS_AUTHORIZED";
const CHECK_USER_IS_AUTHORIZED = "CHECK_USER_IS_AUTHORIZED";
// const ENALBLE_LOGIN_LOADER = "ENALBLE_LOGIN_LOADER";
// const DISABLE_LOGIN_LOADER = "DISABLE_LOGIN_LOADER";
const LOGOUT_ACTION = "LOGOUT_ACTION";
const ENABLE_LOADER = "ENABLE_LOADER";
const DISABLE_LOADER = "DISABLE_LOADER";
const SHOW_NOTIFICATIONS = "SHOW_NOTIFICATIONS";
const HIDE_NOTIFICATIONS = "HIDE_NOTIFICATIONS";
const SET_AUTH_INFO = "SET_AUTH_INFO";
const RESET_STORE = "RESET_STORE";
const RESET_ALL = "RESET_ALL";

const consttypes = {
    CHECK_USER_IS_AUTHORIZED,
    LOGOUT_ACTION,
    ENABLE_LOADER,
    DISABLE_LOADER,
    SHOW_NOTIFICATIONS,
    HIDE_NOTIFICATIONS,
    SET_AUTH_INFO,
    RESET_STORE,
    RESET_ALL,
};
export default consttypes;