import { put, takeLatest, call, take } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { push } from 'connected-react-router';
import update from 'immutability-helper';
import moment from 'moment';

import types from './types';
import * as ProjectsService from '../../../services/ProjectsService'
import * as OrganizationService from '../../../services/OrganizationService'
import * as InvitationService from '../../../services/InvitationService'
import * as FocusAreaService from '../../../services/FocusAreaService'
import * as FilesUploadService from '../../../services/FilesUploadService'
import * as Actions from './Actions'
import * as routingConstants from '../../common/constants/routingConstants'
import * as CommonActions from '../../common/redux/AppCommonActions'
import * as CommonAttributes from '../../common/constants/CommonAttributes'
import * as stringUtils from '../../../common/utility/stringUtils';

export function* getOwnerProjects() {
    try {
        let projects = yield call(ProjectsService.GetProjectsAPI);
        yield put(Actions.SetOwnerProjectsSummary(projects));

    } catch (error) {
        toast.error("Error fetching projects.",
            { autoClose: false });
    }
}

export function* getFocusAreas() {
    try {
        let focusareas = yield call(FocusAreaService.GetFocusAreaForOrgAPI);
        yield put(Actions.SetFocusAreas(focusareas));

    } catch (error) {
        toast.error("Error fetching focus areas.",
            { autoClose: false });
    }
}

export function* createProject(input) {
    try {
        let photosmap = [];
        let { project, photos } = input.value;

        for (var element of photos) {
            let payload;
            if (element.id.value === null) {
                let urlvalue = yield call(FilesUploadService.PostFileUploadAPI, element.file);
                payload = {
                    url: urlvalue,
                    description: element.description.value,
                }
            } else {
                payload = {
                    id: element.id.value,
                    url: element.url.value,
                    description: element.description.value,
                }
            }
            photosmap.push(payload);
        };

        project = update(project, {
            photos: { $set: photosmap}
        });

        yield call(ProjectsService.createProjectsAPI, project);
        CommonActions.disableLoader();
        toast.success("Project created successfully.");
        yield put(push(routingConstants.PROJECTS_OWN));

    } catch (error) {
        CommonActions.disableLoader();
        toast.error("Error while creating project.",
            { autoClose: false });
    }
}

export function* editProject(input) {
    try {

        let photosmap = [];
        let { project, photos } = input.value;

        for (var element of photos) {
            let payload;
            if (element.id.value === null) {
                let urlvalue = yield call(FilesUploadService.PostFileUploadAPI, element.file);
                payload = {
                    url: urlvalue,
                    description: element.description.value,
                }
            } else {
                payload = {
                    id: element.id.value,
                    url: element.url.value,
                    description: element.description.value,
                }
            }
            photosmap.push(payload);
        };

        project = update(project, {
            photos: { $set: photosmap}
        });

        let retProject = yield call(ProjectsService.updateProjectAPI, project);
        CommonActions.disableLoader();
        toast.success("Project updated successfully.");
        yield put(push(routingConstants.PROJECTS_OWN));

    } catch (error) {
        CommonActions.disableLoader();
        toast.error("Error while updating project.",
            { autoClose: false });
    }
}


export function* deleteProject(action) {
    try {
        yield call(ProjectsService.deleteProjectsAPI, action.value.id);
        toast.success("Project deleted.");

        let projects = yield call(ProjectsService.GetProjectsAPI);
        yield put(Actions.SetOwnerProjectsSummary(projects));

    } catch (error) {
        toast.error("Error while deleting project.",
            { autoClose: false });
    }
}

export function* fetchProjectTasks(action) {
    try {
        let projectTasks = yield call(ProjectsService.getProjectTasksAPI, action.value);
        yield put(Actions.setProjectTasks(projectTasks));

    } catch (error) {
        toast.error("Error while fetching project tasks.",
            { autoClose: false });
    }
}

export function* postProjectTasks(action) {
    try {
        // console.log(action);
        let projectTasks = yield call(ProjectsService.updateProjectTasks, action.value);
        yield put(Actions.setProjectTasks(projectTasks));
        toast.success("Tasks saved.");
    } catch (error) {
        toast.error("Error while updating project tasks.",
            { autoClose: false });
    }
}

export function* getProjectInfoDetailed(action) {
    try {
        let project = yield call(ProjectsService.getProjectInfoDetailedAPI, action.value);
        yield put(Actions.setProjectInfoDetailed(project));
    } catch (error) {
        toast.error("Error while getting project details.",
            { autoClose: false });
    }
}


export function* getProjectReports(action) {
    try {

        let reports = yield call(ProjectsService.getProjectReportsAPI, action.value);
        yield put(Actions.setProjectReports(reports));
    
    } catch (error) {
        toast.error("Error while getting project reports.",
            { autoClose: false });
    }
}

export function* getInvitesByOrganization(action) {
    try {
        let invites = yield call(InvitationService.getInvitesByOrganizationAPI, action.value);
        yield put(Actions.setInvitationsByOrganization(invites));
    } catch (error) {
        toast.error("Error while fetching invites.",
            { autoClose: false });
    }
}

export function* getIncomingInvitesByOrganization(action) {
    try {
        let invites = yield call(InvitationService.getIncomingInvitesByOrganizationAPI, action.value);
        yield put(Actions.setIncomingInvitationsByOrganization(invites));
    } catch (error) {
        toast.error("Error while fetching invites.",
            { autoClose: false });
    }
}

export function* getOutgoingInvitesByOrganization(action) {
    try {
        let invites = yield call(InvitationService.getOutgoingInvitesByOrganizationAPI, action.value);
        yield put(Actions.setOutgoingInvitationsByOrganization(invites));
    } catch (error) {
        toast.error("Error while fetching invites.",
            { autoClose: false });
    }
}

export function* deleteInvite(action) {
    try {
        
        yield call(InvitationService.deleteInviteAPI, action.value);
        toast.success("Invite deleted successfully.");
        let invites = yield call(InvitationService.getInvitesByOrganizationAPI, action.value.orgid);
        yield put(Actions.setInvitationsByOrganization(invites));
    } catch (error) {
        toast.error(error.data.error, 
            { autoClose: false });
    }
}

export function* acceptInvite(action) {
    try {
        
        yield call(InvitationService.acceptInvitePutAPI, action.value);
        toast.success("Invitation response sent.");
        
        if (action.value.invitationPageType == CommonAttributes.invitesPageType.INCOMING) {
            let invites = yield call(InvitationService.getIncomingInvitesByOrganizationAPI, action.value.orgid);
            yield put(Actions.setIncomingInvitationsByOrganization(invites));    
        } else {
            let invites = yield call(InvitationService.getOutgoingInvitesByOrganizationAPI, action.value.orgid);
            yield put(Actions.setOutgoingInvitationsByOrganization(invites));    
        }

    } catch (error) {
        toast.error(error.data.error, 
            { autoClose: false });
    }
}

export function* rejectInvite(action) {
    try {
        
        yield call(InvitationService.rejectInvitePutAPI, action.value);
        toast.success("Invitation response sent.");

        if (action.value.invitationPageType == CommonAttributes.invitesPageType.INCOMING) {
            let invites = yield call(InvitationService.getIncomingInvitesByOrganizationAPI, action.value.orgid);
            yield put(Actions.setIncomingInvitationsByOrganization(invites));    
        } else {
            let invites = yield call(InvitationService.getOutgoingInvitesByOrganizationAPI, action.value.orgid);
            yield put(Actions.setOutgoingInvitationsByOrganization(invites));    
        }

    } catch (error) {
        toast.error(error.data.error, 
            { autoClose: false });
    }
}

export function* onholdInvite(action) {
    try {
                
        yield call(InvitationService.onHoldInvitePutAPI, action.value);
        toast.success("Invitation response sent.");

        if (action.value.invitationPageType == CommonAttributes.invitesPageType.INCOMING) {
            let invites = yield call(InvitationService.getIncomingInvitesByOrganizationAPI, action.value.orgid);
            yield put(Actions.setIncomingInvitationsByOrganization(invites));    
        } else {
            let invites = yield call(InvitationService.getOutgoingInvitesByOrganizationAPI, action.value.orgid);
            yield put(Actions.setOutgoingInvitationsByOrganization(invites));    
        }

    } catch (error) {
        toast.error(error.data.error, 
            { autoClose: false });
    }
}

export function* withdrawInvite(action) {
    try {
                
        yield call(InvitationService.withdrawInviteAPI, action.value);
        toast.success("Invitation withdrawn successfully.");

        if (action.value.invitationPageType == CommonAttributes.invitesPageType.INCOMING) {
            let invites = yield call(InvitationService.getIncomingInvitesByOrganizationAPI, action.value.orgid);
            yield put(Actions.setIncomingInvitationsByOrganization(invites));    
        } else {
            let invites = yield call(InvitationService.getOutgoingInvitesByOrganizationAPI, action.value.orgid);
            yield put(Actions.setOutgoingInvitationsByOrganization(invites));    
        }

    } catch (error) {
        toast.error(error.data.error, 
            { autoClose: false });
    }
}

export function* getOwnerProjectsList() {
    try {
        let projects = yield call(ProjectsService.GetProjectsAPI, "OWNER");
        let draftprojects = projects.filter(obj => { return obj.status === "PROPOSED" });
        yield put(Actions.setProjectListForInvites(draftprojects));

    } catch (error) {
        toast.error("Error fetching projects.",
            { autoClose: false });
    }
}

export function* searchOrganization(action) {
    try {
        let organizations = yield call(OrganizationService.SearchOrganizationsAPI, action.value);
        yield put(Actions.setSearchOrganizations(organizations));

    } catch (error) {
        toast.error("Error fetching projects.",
            { autoClose: false });
    }
}

export function* getScheduleForProject(action) {
    try {
        let schedules = yield call(ProjectsService.getSchedulesByProjectAPI, action.value);

        let retval = {
            projectid: action.value,
            schedules: schedules 
        }
        yield put(Actions.setScheduleForProject(retval));

    } catch (error) {
        toast.error("Error fetching schedules for the project.",
            { autoClose: false });
    }
}

export function* saveScheduleForProject(action) {
    try {
        let schedule = {};

        if (stringUtils.isNullOrEmpty(action.value.schedule.id)) {
            schedule = yield call(ProjectsService.postScheduleByProjectAPI, action.value);
        } else {
            schedule = yield call(ProjectsService.putScheduleByProjectAPI, action.value);
        }

        let scheduleSetReq = {
            projectid: action.value.projectid,
            schedule: schedule,
        }

        toast.success("Schedule successfully saved.");
        yield put(Actions.updateScheduleForProject(scheduleSetReq));

    } catch (error) {
        toast.error("Error saving schedule for the project.",
            { autoClose: false });
    }
}

export function* deleteScheduleForProject(action) {
    try {
        yield call(ProjectsService.deleteScheduleByProjectAPI, action.value);

        let scheduleSetReq = {
            projectid: action.value.projectid,
            scheduleid: action.value.scheduleid,
        }

        toast.success("Schedule deleted successfully.");
        yield put(Actions.deleteScheduleFromStore(scheduleSetReq));

    } catch (error) {
        toast.error("Error saving schedule for the project.",
            { autoClose: false });
    }
}


export const ProjectOperations = [
    takeLatest(types.FETCH_OWNER_PROJECTS_SUMMARY, getOwnerProjects),
    takeLatest(types.PROJECT_CREATE, createProject),
    takeLatest(types.PROJECT_EDIT, editProject),
    takeLatest(types.PROJECT_DELETE, deleteProject),
    takeLatest(types.FETCH_FOCUS_AREAS, getFocusAreas),
    takeLatest(types.FETCH_PROJECT_REPORTS, getProjectReports),
    takeLatest(types.FETCH_PROJECT_TASKS, fetchProjectTasks),
    takeLatest(types.POST_PROJECT_TASKS, postProjectTasks),
    takeLatest(types.FETCH_PROJECT_INFO_DETAILS, getProjectInfoDetailed),
    takeLatest(types.FETCH_INVITES_BY_ORGANIZATION, getInvitesByOrganization),
    takeLatest(types.FETCH_INCOMING_INVITES_BY_ORGANIZATION, getIncomingInvitesByOrganization),
    takeLatest(types.FETCH_OUTGOING_INVITES_BY_ORGANIZATION, getOutgoingInvitesByOrganization),
    takeLatest(types.DELETE_INVITATION, deleteInvite),
    takeLatest(types.ACCEPT_PROJECT_INVITE, acceptInvite),
    takeLatest(types.REJECT_PROJECT_INVITE, rejectInvite),
    takeLatest(types.ONHOLD_PROJECT_INVITE, onholdInvite),
    takeLatest(types.WITHDRAW_PROJECT_INVITE, withdrawInvite),
    takeLatest(types.FETCH_PROJECTLIST_FOR_INVITES, getOwnerProjectsList),
    takeLatest(types.SEARCH_ORGANIZATIONS, searchOrganization),
    takeLatest(types.FETCH_SCHEDULES_BY_PROJECT, getScheduleForProject),
    takeLatest(types.SAVE_SCHEDULE_FOR_PROJECT, saveScheduleForProject),
    takeLatest(types.DELETE_SCHEDULE_FOR_PROJECT, deleteScheduleForProject),
]
