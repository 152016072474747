import types from './types';

export const logoutUser = (value) => {
    return {
        type: types.LOGOUT_ACTION,
        value: value
    }
};
export const enableLoader = (value) => {
    return {
        type: types.ENABLE_LOADER,
        value: value
    }
};

export const disableLoader = (value) => {
    return {
        type: types.DISABLE_LOADER,
        value: value
    }
};

export const checkUserIsAuthorized = (value) => {
    return {
        type: types.CHECK_USER_IS_AUTHORIZED,
        value: value
    }
};

export const showNotifications = (value) => {
    return {
        type: types.SHOW_NOTIFICATIONS,
        value: value
    }
}

export const hideNotifications = (value) => {
    return {
        type: types.HIDE_NOTIFICATIONS,
        value: value
    }
}

export const setAuthInfo = (value) => {
    return {
        type: types.SET_AUTH_INFO,
        value: value
    }
}

export const resetStore = (value) => {
    return {
        type: types.RESET_STORE,
        value: value
    }    
}

export const resetAll = (value) => {
    return {
        type: types.RESET_ALL,
        value: value
    }    
}

const appcommon = {
    checkUserIsAuthorized,
    logoutUser,
    enableLoader,
    disableLoader,
    showNotifications,
    hideNotifications,
    setAuthInfo,
    resetStore,
    resetAll,
};

export default appcommon;
