


export const removeUserContext = () => {
    // cookies.remove("tokenCreatedAt", { path: '/' });
    // cookies.remove("tokenUpdatedAt", { path: '/' });
    // cookies.remove("accessToken", { path: '/' });
    // cookies.remove("tokenType", { path: '/' });
    // cookies.remove("email", { path: '/' });
    localStorage.removeItem("persist:root");
    // localStorage.removeItem("persist:online-report");
    // localStorage.removeItem("persist:server-administration");
    // localStorage.removeItem("onlineReportProjectNames");
    // localStorage.removeItem("onlineReportSuiteNames");
    // localStorage.removeItem("serverAdministrationProjects");
    // localStorage.removeItem("serverAdministrationSuites");
    // localStorage.removeItem("serverAdministrationFilters");
    // localStorage.removeItem("onlineReportFilters");
    // localStorage.removeItem("prevRunId");
}
