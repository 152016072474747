import types from './types'

const INITIAL_STATE = {
    searchResult: [],
};

const SearchReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.RESET_STORE: {
            return INITIAL_STATE;
        }        
        case types.SET_PROJECTS: {
            let newState = {
                ...state,
                searchResult: action.value
            }
            return newState;
        }
        case types.RESET_PROJECTS: {
            let newState = {
                ...state,
                searchResult: []
            }
            return newState;
        }

        default:
            return state;
    };
};
export default SearchReducer;