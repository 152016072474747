import http from './http';
import config from '../common/constants/AppConstants'

import * as httpCommon from './httpCommon'

export const PostFileUploadAPI = async (file) => {
    let apiUrl = `${config.service.serverUri}${config.service.files.file}`;
    const formData = new FormData();
    formData.append("file", file);
    return new Promise((resolve, reject) => {
        http.post(apiUrl, formData)
            .then(function (response) {
                if (httpCommon.getResponseStatus(response)) {
                    try {
                        resolve(response.data);
                    }
                    catch (error) {
                        console.log(error);
                    }
                }
                else {
                    reject(response.data);
                }
            })
            .catch(function (error) {
                reject(error.response);
            });
    });
}

export const deleteFileAPI = async (url) => {
    let apiUrl = `${config.service.serverUri}${config.service.files.file}`;
    return new Promise((resolve, reject) => {
        http.delete(apiUrl)
            .then(function (response) {
                if (httpCommon.getResponseStatus(response)) {
                    try {
                        resolve(response.data);
                    }
                    catch (error) {
                        console.log(error);
                    }
                }
                else {
                    reject(response.data);
                }
            })
            .catch(function (error) {
                reject(error.response);
            });
    });
}
