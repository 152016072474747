
export const getResponseStatus = (response) => {
    if (getStatus(response) === 200 || getStatus(response) === 201 || getStatus(response) === 202) {
      return true;
    } else {
      return false;
    }
}

export const getStatusCode = (response) => {
    return response['data'].statusCode;
}

export const getStatusText = (response) => {
    return response['data'].status;
}

export const getStatus = (response) => {
    return response.status;
}
