import types from './types';

export const FetchEvents = (value) => {
    return {
        type: types.FETCH_EVENTS_API,
        value: value
    }
};

export const SetEvents = (value) => {
    return {
        type: types.SET_EVENTS,
        value: value
    }
};

export const RegisterEventUser = (value) => {
    return {
        type: types.REGISTER_EVENT_USER,
        value: value
    }
};

export const getLatestProjects = (value) => {
    return {
        type: types.FETCH_LATEST_PROJECTS,
        value: value
    }
};

export const setLatestProjects = (value) => {
    return {
        type: types.SET_LATEST_PROJECTS,
        value: value
    }
};


const HomePageActions = {
    FetchEvents,
    SetEvents,
    RegisterEventUser,
    getLatestProjects,
    setLatestProjects,
};

export default HomePageActions;