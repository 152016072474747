import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from '../Header';
import AppCommonActions from '../redux/AppCommonActions';
import Footer from '../Footer';
import MyLoader from './MyLoader'

class HomepageLayout extends Component {

    render() {
        
        const { children } = this.props;

        let currentRoute = this.props.history.location["pathname"];
        
        const headerComponentProps = {
            currentRoute: currentRoute,
            App: this.props.App,
            oidc: this.props.oidc,
            logoutUser: this.props.logoutUser,
            license: this.props.license,
            history: this.props.history,
        }

        return (
            <React.Fragment>
                <MyLoader active={this.props.App.isLoading}>
                {/* <LoadingOverlay active={this.props.App.isLoading} 
                spinner={<PuffLoader css={override} size={150} color={"#123abc"} loading={this.props.App.isLoading} />}> */}
                    <>
                        { <Header { ...headerComponentProps } /> }
                        {/* Main content */}
                        { children }
                        <Footer/>
                    </>

                    {/* <main className="main">                        
                        { <Header { ...headerComponentProps } /> }
                        { children }
                        <Footer/>
                    </main> */}
                {/* </LoadingOverlay> */}
                </MyLoader>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        App: state.App,
        oidc: state.oidc,
        license: state.OrganizationReducer.license,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        logoutUser: () => dispatch(AppCommonActions.logoutUser()),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(HomepageLayout);