import { put, takeLatest, call } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import types from './types';
import * as routingConstants from '../../common/constants/routingConstants'
import { push } from 'connected-react-router';
import * as sessionService from '../../../services/sessionManagement'
import AppCommonActions from '../../common/redux/AppCommonActions'
import projectActions from '../../projects/redux/Actions'

import * as AuthenticationService from '../../../services/AuthenticationService'
import * as ProjectsService from '../../../services/ProjectsService'

//User Registration API call
export function* RegisterUser(signInUser) {
    try {
        yield put(AppCommonActions.enableLoader())
        yield call(AuthenticationService.RegisterUserAPI, signInUser.value);
        toast.success("Successfully registered. Check mail on your registered email, verify email and setup password.", 
            { autoClose: true });

        yield put(push(`${routingConstants.LOGIN}`));
        yield put(AppCommonActions.disableLoader());

    } catch (error) {
        yield put(AppCommonActions.disableLoader());
        toast.error("Error while registering user.", 
            { autoClose: false });
    }
}

export const LoginOperations = [
    takeLatest(types.REGISTER_USER, RegisterUser),
]
