export const ENCRYPTION_KEY = '48354a811fc54160a7676de53dac69aa';
export const ENCRYPTION_SALT = "d7be0966c6d345b2b4b24fbe28a93175";
export const ENCRYPTION_IV = "e3c8fea582824c009ff29df78a06cd0d";
export const API_TIMEOUT = 20000;
import configEnv from './configEnv'

const CONFIG_VALUES = {
    // BASE_URI: ,
    AUTH_URI: '/auth',
    AUTH_SIGNUP_URI: 'public/v1/registration/organization',
    AUTH_LOGIN_URI: '/login',
    PROJECT_URI: "graph/v1/projects",
    PROJECT_OP: "/project",
    EVENTS: "public/v1/registration/events",
    ORGANIZATION_BASE: "graph/v1/organizations",
    ORGANIZATION_URI: "graph/v1/organizations/organization",
    ORGANIZATION_SEARCH: "search",
    DOCUMENTS: "/documents",
    ORGANIZATION_ME: "graph/v1/organizations/me",
    ORGANIZATION_CHECK_URL_AVAILABILITY: "graph/v1/organizations/checkurlavailability",
    ORGANIZATION_NGO_TYPES: "graph/v1/organizations/ngotypes",
    ORGANIZATION_CORPORATE_TYPES: "graph/v1/organizations/industrysectors",
    FOCUS_AREA_URI: "graph/v1/focusareas",
    TASKS: "tasks",
    QUERY_USER: "public/v1/registration/userquery",
    QUERY_ORGANIZATION: "public/v1/registration/organizationquery",
    PUBLIC_ORGNIZATION: "public/v1/organizations/organization",
    PUBLIC_ORGNIZATION_MEDIA: "media",
    PUBLIC_ORGNIZATION_TEAM: "team",
    PUBLIC_PROJECTS: "public/v1/projects",
    LATEST_PROJECTS: "public/v1/projects/latest",
    FETCH_LATEST_LIMIT: "5",
    SEARCH: "search",
    FILES_UPLOAD: "graph/v1/files",
    PHOTOS: "photos",
    INVITE: "invite",
    INVITES: "invites",
    REPORTS: "reports",
    REPORTS_URI: "graph/v1/reports",
    PROJECT_LIST: "projectlist",
    REPORT: "report",
    ORG_INVITES: "invites",
    ACCEPT_INVITE: "accept",
    REJECT_INVITE: "reject",
    ONHOLD_INVITE: "onhold",
    WITHDRAW_INVITE: "withdraw",
    REPORT_PUBLISH: "publish",
    REPORT_UNPUBLISH: "unpublish",
    SCHEDULES: "schedules",
    SCHEDULE: "schedule",
    NOTIFICATIONS_URI: "graph/v1/notifications",
    NOTIFICATION: "notification",

};

const Config = {
    service: {
        serverUri: configEnv.springServiceBaseUrl,
        openapi: {
            event: CONFIG_VALUES.EVENTS,
            orgquery: CONFIG_VALUES.QUERY_ORGANIZATION,
            userquery: CONFIG_VALUES.QUERY_USER,
            latestprojects: CONFIG_VALUES.LATEST_PROJECTS,
            latestProjectLimit: CONFIG_VALUES.FETCH_LATEST_LIMIT,
        },
        authentication: {
            signup : CONFIG_VALUES.AUTH_SIGNUP_URI, 
            // "login" : CONFIG_VALUES.AUTH_URI + CONFIG_VALUES.AUTH_LOGIN_URI,
            // "project_uri" : CONFIG_VALUES.PROJECT_URI,
            // "clientId": CONFIG_VALUES.CLIENT_ID,
            // "secret": CONFIG_VALUES.CLIENT_SECRET,
            // "baseUri": window.location.origin,
            // "uri": CONFIG_VALUES.AUTH_URI,
            // "tokenUri": CONFIG_VALUES.AUTH_TOKEN_URI,
            // "logoutUri": CONFIG_VALUES.AUTH_LOGOUT_URI,
            // "version": CONFIG_VALUES.VERSION
        },
        project: {
            uri: CONFIG_VALUES.PROJECT_URI,
            ops: CONFIG_VALUES.PROJECT_URI + CONFIG_VALUES.PROJECT_OP,
            invited: CONFIG_VALUES.PROJECT_URI + '/' + CONFIG_VALUES.INVITES,
            tasks: CONFIG_VALUES.TASKS,
            search: CONFIG_VALUES.SEARCH,
            photos: CONFIG_VALUES.PHOTOS,
            invite: CONFIG_VALUES.INVITE,
            reports: CONFIG_VALUES.REPORTS,
            acceptInvite: CONFIG_VALUES.ACCEPT_INVITE,
            rejectInvite: CONFIG_VALUES.REJECT_INVITE,
            onHoldInvite: CONFIG_VALUES.ONHOLD_INVITE,
            withdrawInvite: CONFIG_VALUES.WITHDRAW_INVITE,
            schedules: CONFIG_VALUES.SCHEDULES,
            schedule: CONFIG_VALUES.SCHEDULE,
        },
        organization: {
            search: CONFIG_VALUES.ORGANIZATION_BASE + '/' + CONFIG_VALUES.ORGANIZATION_SEARCH,
            me: CONFIG_VALUES.ORGANIZATION_ME,
            checkurlavailability: CONFIG_VALUES.ORGANIZATION_CHECK_URL_AVAILABILITY,
            uri: CONFIG_VALUES.ORGANIZATION_URI,
            corp_types: CONFIG_VALUES.ORGANIZATION_CORPORATE_TYPES,
            ngo_types: CONFIG_VALUES.ORGANIZATION_NGO_TYPES,
            documents: CONFIG_VALUES.DOCUMENTS,
            invites: CONFIG_VALUES.ORG_INVITES,
        },
        focusarea: {
            uri: CONFIG_VALUES.FOCUS_AREA_URI,
        },
        public: {
            organization: CONFIG_VALUES.PUBLIC_ORGNIZATION,
            projects: CONFIG_VALUES.PUBLIC_PROJECTS,
            media: CONFIG_VALUES.PUBLIC_ORGNIZATION_MEDIA,
            team: CONFIG_VALUES.PUBLIC_ORGNIZATION_TEAM,
        },
        files: {
            file: CONFIG_VALUES.FILES_UPLOAD,
        },
        reports: {
            uri: CONFIG_VALUES.REPORTS_URI,
            projectlist: CONFIG_VALUES.PROJECT_LIST,
            report: CONFIG_VALUES.REPORT,
            publish: CONFIG_VALUES.REPORT_PUBLISH,
            unpublish: CONFIG_VALUES.REPORT_UNPUBLISH,
        },
        notifications: {
            uri: CONFIG_VALUES.NOTIFICATIONS_URI,
            notification: CONFIG_VALUES.NOTIFICATION,
        }
    }
}
export default Config;


