import { put, takeLatest, call, take } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { push } from 'connected-react-router';
import types from './types';
import * as ReportsService from '../../../services/ReportsService';
import * as ProjectsService from '../../../services/ProjectsService';
import * as Actions from './Actions';
import * as routingConstants from '../../common/constants/routingConstants';
import * as AppCommonActions from '../../common/redux/AppCommonActions';


export function* fetchOwnReports(action) {
    try {
        let reports = yield call(ReportsService.getOwnReportsSummaryAPI);
        yield put(Actions.setOwnReportsSummary(reports));

    } catch (error) {
        toast.error("Error fetching reports.", 
            { autoClose: false });
        yield put(push(routingConstants.NOT_FOUND));
    }
}

export function* fetchProjectList(action) {
    try {
        let projects = yield call(ReportsService.getProjectListAPI);
        yield put(Actions.setProjectList(projects));

    } catch (error) {
        toast.error("Error fetching projects.", 
            { autoClose: false });
        yield put(push(routingConstants.NOT_FOUND));
    }
}

export function* createReport(action) {
    try {
        yield call(ReportsService.createReportpostAPI, action.value);

        toast.success("Report created.");
        let reports = yield call(ReportsService.getOwnReportsSummaryAPI);
        yield put(Actions.setOwnReportsSummary(reports));

    } catch (error) {
        toast.error("Error creating report.", 
            { autoClose: false });
    }
}

export function* editReport(action) {
    try {
        yield call(ReportsService.editReportpostAPI, action.value);
        toast.success("Report edited successfully.");
        yield put(push(routingConstants.REPORTS_OWN));
        // let reports = yield call(ReportsService.getOwnReportsSummaryAPI);
        // yield put(Actions.setOwnReportsSummary(reports));
    } catch (error) {
        toast.error("Error updating report.", 
            { autoClose: false });
    }
}

export function* deleteReport(action) {
    try {
        yield call(ReportsService.deleteReportAPI, action.value);
        toast.success("Report deleted successfully.");

        let reports = yield call(ReportsService.getOwnReportsSummaryAPI);
        yield put(Actions.setOwnReportsSummary(reports));
    } catch (error) {
        toast.error("Error deleting report.", 
            { autoClose: false });
    }
}

export function* getReportDetailed(action) {
    try {
        let report = yield call(ReportsService.getOwnReportDetailedAPI, action.value);
        // let reports = yield call(ReportsService.getOwnReportsSummaryAPI);
        yield put(Actions.setReportDetailed(report));
    } catch (error) {
        toast.error("Error fetching report.", 
            { autoClose: false });
    }
}

export function* getProjectTasks(action) {
    try {
        let tasks = yield call(ProjectsService.getProjectTasksAPI, action.value);
        yield put(Actions.setProjectTasks(tasks));
    } catch (error) {
        toast.error("Error fetching project details.", 
            { autoClose: false });
    }
}

export function* reportPublish(action) {
    try {        
        yield call(ReportsService.publishReportputAPI, action.value);
        toast.success("Report published.");
        let reports = yield call(ReportsService.getOwnReportsSummaryAPI);
        yield put(Actions.setOwnReportsSummary(reports));
    } catch (error) {
        toast.error("Error publishing report.", 
            { autoClose: false });
    }
}

export function* reportUnPublish(action) {
    try {
        yield call(ReportsService.unpublishReportputAPI, action.value);
        toast.success("Report unpublished.");

        let reports = yield call(ReportsService.getOwnReportsSummaryAPI);
        yield put(Actions.setOwnReportsSummary(reports));
    } catch (error) {
        toast.error("Error unpublishing report.", 
            { autoClose: false });
    }
}


export const ReportsOperations = [
    takeLatest(types.FETCH_OWN_REPORTS_SUMMARY, fetchOwnReports),
    takeLatest(types.FETCH_PROJECT_LIST, fetchProjectList),
    takeLatest(types.CREATE_REPORT, createReport),
    takeLatest(types.EDIT_REPORT, editReport),
    takeLatest(types.DELETE_REPORT, deleteReport),
    takeLatest(types.FETCH_REPORT_DETAILED, getReportDetailed),
    takeLatest(types.REPORT_PUBLISH, reportPublish),
    takeLatest(types.REPORT_UNPUBLISH, reportUnPublish),
]
