import { createUserManager } from 'redux-oidc';
import configEnv from '../constants/configEnv'
import * as DateHelper from './DateHelper'
import { store } from '../../store';

const userManagerConfig = {
    client_id: 'react-client',
    redirect_uri: `${window.location.port ? window.location.protocol + '//' + window.location.hostname + ':' + window.location.port : window.location.protocol + '//' + window.location.hostname}/callback`,
    response_type: "code",
    scope: 'openid profile offline_access',
    authority: configEnv.keycloakAuthority,
    silent_redirect_uri: `${window.location.port ? window.location.protocol + '//' + window.location.hostname + ':' + window.location.port : window.location.protocol + '//' + window.location.hostname}/silent-renew.html`,
    post_logout_redirect_uri: `${window.location.port ? window.location.protocol + '//' + window.location.hostname + ':' + window.location.port : window.location.protocol + '//' + window.location.hostname}/callback`,
    automaticSilentRenew: true,
    filterProtocolClaims: true,
    loadUserInfo: true,
};

const userManager = createUserManager(userManagerConfig);

export default userManager;


export const tokenValid = () => {
    let user = store.getState().oidc.user;
    return !DateHelper.IsExpired(user.expires_at);
}