import { put, takeLatest, call, delay } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import types from './types';
import actions from './Actions';
import AppCommonActions from '../../common/redux/AppCommonActions'
import * as OpenEndpointsService from '../../../services/OpenEndpoints'

// Fetch events
export function* FetchEventsAPI(eventtype) {
    try {
        // yield put(AppCommonActions.enableLoader());
        // yield delay(5000);
        let eventResponseObj = yield call(OpenEndpointsService.GetEventsAPI, eventtype.value);
        yield put(actions.SetEvents(eventResponseObj));
        // yield put(AppCommonActions.disableLoader());
        // toast.success("fetched events...", 
        // { autoClose: true });

    } catch (error) {
        // yield put(AppCommonActions.disableLoader());
        toast.error("Unable to fetch events", 
            { autoClose: false });
    }
}

export function* RegisterUserForEvent(request) {

    try {
        yield call(OpenEndpointsService.RegisterEventUserAPI, request.value.eventid, request.value.user);
        toast.success("Successfully registered for the event. You will receive a confirmation mail shortly.", { autoClose: true });
        
    } catch (error) {
        toast.error("Unable to register user for event", 
            { autoClose: false });
    }
}

export function* fetchLatestProjects() {
    try {
        let projects = yield call(OpenEndpointsService.getLatestProjectsAPI);
        yield put(actions.setLatestProjects(projects));
    } catch (error) {
        toast.error("Unable to fetch projects", 
            { autoClose: false });
    }
}

export const HomePageOperations = [
    takeLatest(types.FETCH_EVENTS_API, FetchEventsAPI),
    takeLatest(types.REGISTER_EVENT_USER, RegisterUserForEvent),
    takeLatest(types.FETCH_LATEST_PROJECTS, fetchLatestProjects),
]
