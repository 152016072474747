const AUTHENTICATE_USER = "AUTHENTICATE_USER";
const REGISTER_USER = "REGISTER_USER";
const ENALBLE_LOGIN_LOADER = "ENALBLE_LOGIN_LOADER";
const DISABLE_LOGIN_LOADER = "DISABLE_LOGIN_LOADER";
// const SET_ORGANIZATION_ID = "SET_ORGANIZATION_ID"; 
// const FETCH_PROJECTS = 'FETCH_PROJECTS';
// const SET_PROJECTS = 'SET_PROJECTS';
// const SET_ORGANIZATION = 'SET_ORGANIZATION';
// const FETCH_ORGANIZATION = 'FETCH_ORGANIZATION';
// const PUT_ORGANIZATION = 'PUT_ORGANIZATION';
// const SET_SEGMENTS = 'SET_SEGMENTS';
// const FETCH_SEGMENTS = 'FETCH_SEGMENTS';
// const POST_PROJECTS = 'POST_PROJECTS';
// const FETCH_PROJECT_DETAILS = 'FETCH_PROJECT_DETAILS';

const types = {
    // AUTHENTICATE_USER,
    REGISTER_USER,
    ENALBLE_LOGIN_LOADER,
    DISABLE_LOGIN_LOADER,
};

export default types;