import types from './types';

export const searchProjects = (value) => {

    return {
        type: types.FETCH_PROJECTS,
        value: value
    }
};

export const setSearchProjects = (value) => {

    return {
        type: types.SET_PROJECTS,
        value: value
    }
};

export const resetSearchProjects = (value) => {

    return {
        type: types.RESET_PROJECTS,
        value: value
    }
};

export const createInvite = (value) => {

    return {
        type: types.CREATE_INVITE,
        value: value
    }
};

// export const updateInvite = (value) => {

//     return {
//         type: types.UPDATE_INVITE,
//         value: value
//     }
// };

export const resetStore = (value) => {
    return {
        type: types.RESET_STORE,
        value: value
    }    
}

const SearchActions = {
    searchProjects,
    setSearchProjects,
    resetSearchProjects,
    createInvite,
    // updateInvite,
    resetStore,
};

export default SearchActions;