import { put, takeLatest, call, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import types from './types';
// import AppCommonActions from './AppCommonActions'
import userManager from '../../../common/utility/userManager'
import * as selectors from './Selector';
import * as DateHelper from '../../../common/utility/DateHelper'
import * as routingConstants from '../../common/constants/routingConstants'
import update from 'immutability-helper';
import AppCommonActions from './AppCommonActions'
import * as sessionManagement from '../../../services/sessionManagement'
import projectActions from '../../projects/redux/Actions';
import organizationActions from '../../OrganizationProfile/redux/Actions';
import reportsActions from '../../reports/redux/Actions';
import searchActions from '../../Search/redux/Actions';

export function* logoutUserOperation(value) {
    try {
        yield (userManager.removeUser());
        yield (sessionManagement.removeUserContext());        
        let authInfo = {
            userLoggedin: false,
            tokenExpired: false,
            user: {
                firstName: null,
                lastName: null,
            }
        };
        yield put(AppCommonActions.setAuthInfo(authInfo));
        yield put(AppCommonActions.resetAll());
        yield (userManager.signoutRedirect());

    } catch (error) {
        yield (userManager.signoutRedirect());
        console.log(error)
    }
};

export function* checkUserIsAuthorized(action) {
    try {

        let authInfo = {
            userLoggedin: false,
            tokenExpired: false,
            user: {
                firstName: null,
                lastName: null,
            }
        };

        
        let user = yield select(selectors.oidcReducerData);
        if (user !== null) {
            let userTokenExpired = false;
            let dateNowValue = Date.now() / 1000;
            if (user.expires_at < dateNowValue) {
                userTokenExpired = true;
                console.log("User token expired")
            }
            authInfo = update(authInfo, {
                userLoggedin: { $set: true},
                tokenExpired: { $set: userTokenExpired},
                user: {
                    firstName: { $set: user.profile.given_name},
                    lastName: { $set: user.profile.family_name},
                }    
            });
        } else {
            authInfo = update(authInfo, {
                tokenExpired: { $set: true}
            });
        }

        yield put(AppCommonActions.setAuthInfo(authInfo));

        if (action.value == "/callback" || action.value == "/") {
            return;
        }
        if (authInfo.tokenExpired) {
            yield (userManager.removeUser());
            yield put(userManager.signinRedirect(action.value));
        }
    } catch (error) {
        console.log(error)
    }
};

export function* resetAllstores(value) {
    try {
        yield put(AppCommonActions.resetStore());
        yield put(projectActions.resetStore());
        yield put(organizationActions.resetStore());
        yield put(reportsActions.resetStore());
        yield put(searchActions.resetStore());
        
    } catch (error) {
        console.log(error)
    }
};

export const AppCommonOperations = [
    takeLatest(types.LOGOUT_ACTION, logoutUserOperation),
    takeLatest(types.CHECK_USER_IS_AUTHORIZED, checkUserIsAuthorized),
    takeLatest(types.RESET_ALL, resetAllstores),
]