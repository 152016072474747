// import { LOGIN_ACTION } from '../actions/AppCommon';
import types from './types'
import update from 'immutability-helper';

const INITIAL_STATE = {
    ownerprojects: [],
    focusareas:[],
    projectDetails: {},
    projectTasks: [],
    projectInfoDetails: {},
    invites: [],
    incomingInvites: [],
    outgoingInvites: [],
    projectreports: [],
    projectListForInvites: [],
    organizationSearchResult: [],
    schedules: [],
};

const ProjectReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.RESET_STORE: {
            return INITIAL_STATE;
        }
        case types.SET_OWNER_PROJECTS_SUMMARY: {
            let newState = {
                ...state,
                ownerprojects: action.value
            }
            return newState;
        }
        case types.RESET_OWNER_PROJECTS_SUMMARY: {
            let newState = {
                ...state,
                ownerprojects: []
            }
            return newState;
        }
        case types.SET_PROJECT_REPORTS: {
            let newState = {
                ...state,
                projectreports: action.value
            }
            return newState;
        }
        case types.RESET_PROJECT_REPORTS: {
            let newState = {
                ...state,
                projectreports: []
            }
            return newState;
        }
        case types.SET_FOCUS_AREAS: {
            let newState = {
                ...state,
                focusareas: action.value
            }
            return newState;
        }
        case types.SET_PROJECT_TASKS: {
            let newState = {
                ...state,
                projectTasks: action.value
            }
            return newState;
        }
        case types.SET_PROJECT_INFO_DETAILS: {
            let newState = {
                ...state,
                projectInfoDetails: action.value
            }
            return newState;
        }
        case types.RESET_PROJECT_INFO_DETAILS: {
            let newState = {
                ...state,
                projectInfoDetails: {}
            }
            return newState;
        }
        case types.SET_INVITES_BY_ORGANIZATION: {
            let newState = {
                ...state,
                invites: action.value
            }
            return newState;
        }
        case types.RESET_INVITES_BY_ORGANIZATION: {
            let newState = {
                ...state,
                invites: []
            }
            return newState;
        }
        case types.SET_INCOMING_INVITES_BY_ORGANIZATION: {
            let newState = {
                ...state,
                incomingInvites: action.value
            }
            return newState;
        }
        case types.RESET_INCOMING_INVITES_BY_ORGANIZATION: {
            let newState = {
                ...state,
                incomingInvites: []
            }
            return newState;
        }
        case types.SET_OUTGOING_INVITES_BY_ORGANIZATION: {
            let newState = {
                ...state,
                outgoingInvites: action.value
            }
            return newState;
        }
        case types.RESET_OUTGOING_INVITES_BY_ORGANIZATION: {
            let newState = {
                ...state,
                outgoingInvites: []
            }
            return newState;
        }
        case types.SET_PROJECTLIST_FOR_INVITES: {
            let newState = {
                ...state,
                projectListForInvites: action.value
            }
            return newState;
        }
        case types.RESET_PROJECTLIST_FOR_INVITES: {
            let newState = {
                ...state,
                projectListForInvites: []
            }
            return newState;
        }
        case types.SET_SEARCH_RESULT: {
            let newState = {
                ...state,
                organizationSearchResult: action.value
            }
            return newState;
        }
        case types.RESET_SEARCH_RESULT: {
            let newState = {
                ...state,
                organizationSearchResult: []
            }
            return newState;
        }
        case types.SET_SCHEDULE_FOR_PROJECT: {
            let retval = state;
            let projectIndex = state.ownerprojects.findIndex(item => item.id == action.value.projectid);            
            if (projectIndex != -1) {                
                retval = update(state, {
                    ownerprojects: { [projectIndex]: { schedules: {$set: action.value.schedules}}}
                });
            }
            return retval;
        }
        case types.UPDATE_SCHEDULE_FOR_PROJECT: {
            let retval = update(state, {
                ownerprojects: {
                    $apply: a => a.map((project) => {
                        if (project.id === action.value.projectid) {
                            let scheduleIndex = project.schedules.findIndex(schedule => schedule.id == action.value.schedule.id);
                            let updatedproject;
                            if (scheduleIndex != -1) {
                                updatedproject = update(project, {
                                    schedules: {[scheduleIndex]: {$set: action.value.schedule}}
                                });
                            } else {
                                updatedproject = update(project, {
                                    schedules: {$push: [action.value.schedule]}
                                });
                            }
                            return updatedproject;
                        } else {
                            return project;
                        }
                    })
                }
            });
            return retval;
        }
        case types.DELETE_SCHEDULE_FROM_STORE: {
            let retval = update(state, {
                ownerprojects: {
                    $apply: a => a.map((project) => {
                        if (project.id === action.value.projectid) {
                            let scheduleIndex = project.schedules.findIndex(schedule => schedule.id == action.value.scheduleid);
                            let updatedproject;
                            if (scheduleIndex != -1) {
                                updatedproject = update(project, {
                                    schedules: {$splice: [[scheduleIndex, 1]] }
                                });
                            } else {
                                updatedproject = project;
                            }
                            return updatedproject;
                        } else {
                            return project;
                        }
                    })
                }
            });
            return retval;

        }


        default:
            return state;
    };
};
export default ProjectReducer;