import React from 'react';
import { Route } from 'react-router-dom';

const LayoutRoute = ({ component: Component, layout: Layout, ...rest }) => {
    return <Route
        {...rest}
        render={props => {
            return <Layout {...props}>
                <Component {...props} />
            </Layout>
        }
        }
    />
};

export default LayoutRoute;
