export const SITE_HOME = '/';
export const LOGIN = '/login';
export const REGISTER = '/register';
export const LOGIN_HOME = '/home';
export const ORGANIZATION_PROFILE = LOGIN_HOME + '/profile';
export const ORGANIZATION_SEARCH = LOGIN_HOME + '/search';
export const PROJECTS = LOGIN_HOME + '/projects';
export const PROJECTS_OWN = PROJECTS + '/own';
export const MANAGE_PROJECT_INVITES = PROJECTS + '/invites';
export const MANAGE_INCOMING_PROJECT_INVITES = MANAGE_PROJECT_INVITES + '/incoming';
export const MANAGE_OUTGOING_PROJECT_INVITES = MANAGE_PROJECT_INVITES + '/outgoing';
export const CREATE_PROJECT_INVITE = MANAGE_PROJECT_INVITES + '/create';
export const PROJECTS_DRAFT = PROJECTS + '/draft';
export const PROJECTS_INPROGRESS = PROJECTS + '/inprogress';
export const PROJECTS_COMPLETE = PROJECTS + '/complete';
export const PROJECTS_CREATE = PROJECTS + '/create';
export const PROJECT_DETAILS = PROJECTS + '/project';
// export const PROJECT_INVITES = PROJECT_DETAILS + '/invites';
export const PROJECT_EDIT = PROJECT_DETAILS + '/edit';
// export const PROJECT_PHASES = PROJECT_DETAILS + '/phases';
export const PUBLIC_ORGANIZATION_HOME =  '/public/organization/:orgname';
export const PUBLIC_ORGANIZATION_ACTIVE_PROJECTS =  '/public/organization/:orgname/projects';
export const PUBLIC_ORGANIZATION_COMPLETE_PROJECTS =  '/public/organization/:orgname/pastprojects';
export const PUBLIC_ORGANIZATION_MEDIA =  '/public/organization/:orgname/media';
export const PUBLIC_ORGANIZATION_LEGAL =  '/public/organization/:orgname/legal';
export const PUBLIC_ORGANIZATION_OURTEAM =  '/public/organization/:orgname/ourteam';
export const NOT_FOUND =  '/notfound';
export const CALLBACK = '/callback';
export const TERMS_AND_CONDITIONS = '/csrnexus-terms-and-conditions';
export const PRIVACY_POLICY = '/csrnexus-privacy-policy';
export const PRICING = "/pricing";
export const REPORTS_OWN = '/reports/own';
export const REPORT_EDIT = '/report/edit';
export const REPORT_VIEW = '/report/view';
export const MENTORS = '/mentors';
