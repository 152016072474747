import types from './types';
import * as stringUtils from '../../../common/utility/stringUtils';
import update from 'immutability-helper';

const INITIAL_STATE = {
    organization: {},
    corpTypes: [],
    ngoTypes: [],
    license: {
        licenseExpired: false,
    },
    notifications: [],
};

const OrganizationReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.RESET_STORE: {
            return INITIAL_STATE;
        }
        case types.SET_ORGANIZATION_DETAILS: {
            let newState = {
                ...state,
                organization: action.value
            }
            return newState;
        }
        case types.SET_CORP_TYPES: {
            let newState = {
                ...state,
                corpTypes: action.value
            }
            return newState;
        }
        case types.SET_NGO_TYPES: {
            let newState = {
                ...state,
                ngoTypes: action.value
            }
            return newState;
        }
        case types.SET_LICENSE: {
            let newState = {
                ...state,
                license: action.value
            }
            return newState;
        }
        case types.SET_NOTIFICATIONS: {
            let newState = {
                ...state,
                notifications: action.value
            }
            return newState;
        }
        case types.DELETE_NOTIFICATION_STORE: {
            let retval = update(state, {
                notifications: {
                    $apply: inner => {
                        let index = stringUtils.getJsonIndex(inner, "id", action.value);
                        if (index != -1) {
                            return update(inner, { $splice: [[index, 1]] });
                        }
                        return inner;
                    }
                }
            });
            return retval;
        }
        case types.MARK_NOTIFICATION_READ_STORE: {
            let retval = update(state, {
                notifications: {
                    $apply: a => a.map((item) => {
                        if (item.id === action.value.id) return {
                            ...item,
                            read: false
                        };
                        return item;
                    })
                }
            });
            return retval;
        }
        default:
            return state;
    };
};
export default OrganizationReducer;