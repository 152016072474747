// import { LOGIN_ACTION } from '../actions/AppCommon';
import types from './types'

const INITIAL_STATE = {
    isLoading: false,
    isUserAuthorized: false,
    notificationsShow: false,
    authInfo: {
        userLoggedin: false,
        tokenExpired: false,
        user: {
            firstName: null,
            lastName: null,
        }
    }
};

const AppCommonReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.RESET_STORE: {
            return INITIAL_STATE;
        }
        case types.ENABLE_LOADER: {
            let newState = {
                ...state,
                isLoading: true,
            }
            return newState;
        }
        case types.DISABLE_LOADER: {
            let newState = {
                ...state,
                isLoading: false,
            }
            return newState;
        }
        case types.SHOW_NOTIFICATIONS: {
            let newState = {
                ...state,
                notificationsShow: true,
            }
            return newState;
        }
        case types.HIDE_NOTIFICATIONS: {
            let newState = {
                ...state,
                notificationsShow: false,
            }
            return newState;
        }
        case types.SET_AUTH_INFO: {
            let newState = {
                ...state,
                authInfo: action.value,
            }
            return newState;
        }


        default:
            return state
    };
};
export default AppCommonReducer;