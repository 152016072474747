const FETCH_OWN_REPORTS_SUMMARY = "FETCH_OWN_REPORTS_SUMMARY";
const SET_OWN_REPORTS_SUMMARY = "SET_OWN_REPORTS_SUMMARY";
const RESET_OWN_REPORTS_SUMMARY = "RESET_OWN_REPORTS_SUMMARY";
const FETCH_PROJECT_LIST = "FETCH_PROJECT_LIST";
const SET_PROJECT_LIST = "SET_PROJECT_LIST";
const RESET_PROJECT_LIST = "RESET_PROJECT_LIST";
const CREATE_REPORT = "CREATE_REPORT";
const EDIT_REPORT = "EDIT_REPORT";
const DELETE_REPORT = "DELETE_REPORT";
const FETCH_REPORT_DETAILED = "FETCH_REPORT_DETAILED";
const SET_REPORT_DETAILED = "SET_REPORT_DETAILED";
const RESET_REPORT_DETAILED = "RESET_REPORT_DETAILED";
const FETCH_PROJECT_TASKS = "FETCH_PROJECT_TASKS";
const SET_PROJECT_TASKS = "SET_PROJECT_TASKS";
const RESET_PROJECT_TASKS = "RESET_PROJECT_TASKS";
const REPORT_PUBLISH = "REPORT_PUBLISH";
const REPORT_UNPUBLISH = "REPORT_UNPUBLISH";
const RESET_STORE = "RESET_STORE";


const types = {
    FETCH_OWN_REPORTS_SUMMARY,
    SET_OWN_REPORTS_SUMMARY,
    RESET_OWN_REPORTS_SUMMARY,
    FETCH_PROJECT_LIST,
    SET_PROJECT_LIST,
    RESET_PROJECT_LIST,
    CREATE_REPORT,
    EDIT_REPORT,
    DELETE_REPORT,
    FETCH_REPORT_DETAILED,
    SET_REPORT_DETAILED,
    RESET_REPORT_DETAILED,
    FETCH_PROJECT_TASKS,
    SET_PROJECT_TASKS,
    RESET_PROJECT_TASKS,
    REPORT_PUBLISH,
    REPORT_UNPUBLISH,
    RESET_STORE,
};

export default types;