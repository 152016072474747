import http from './http';
import config from '../common/constants/AppConstants'
import * as httpCommon from './httpCommon'
import qs from 'qs'


export const getInvitesByOrganizationAPI = async (payload) => {
    let apiUrl = `${config.service.serverUri}${config.service.organization.uri}/${payload}/${config.service.organization.invites}`

    return new Promise((resolve, reject) => {
        http.get(apiUrl)
            .then(function (response) {
                if (httpCommon.getResponseStatus(response)) {
                    try {
                        resolve(response.data);
                    }
                    catch (error) {
                        console.log(error);
                    }
                }
                else {
                    reject(response.data);
                }
            })
            .catch(function (error) {
                reject(error.response);
            });
    });
}

export const getIncomingInvitesByOrganizationAPI = async (payload) => {
    let apiUrl = `${config.service.serverUri}${config.service.organization.uri}/${payload}/${config.service.organization.invites}`

    return new Promise((resolve, reject) => {
        http.get(apiUrl, { params: { invitation: "INCOMING" } })
            .then(function (response) {
                if (httpCommon.getResponseStatus(response)) {
                    try {
                        resolve(response.data);
                    }
                    catch (error) {
                        console.log(error);
                    }
                }
                else {
                    reject(response.data);
                }
            })
            .catch(function (error) {
                reject(error.response);
            });
    });
}

export const getOutgoingInvitesByOrganizationAPI = async (payload) => {
    let apiUrl = `${config.service.serverUri}${config.service.organization.uri}/${payload}/${config.service.organization.invites}`

    return new Promise((resolve, reject) => {
        http.get(apiUrl, { params: { invitation: "OUTGOING" } })
            .then(function (response) {
                if (httpCommon.getResponseStatus(response)) {
                    try {
                        resolve(response.data);
                    }
                    catch (error) {
                        console.log(error);
                    }
                }
                else {
                    reject(response.data);
                }
            })
            .catch(function (error) {
                reject(error.response);
            });
    });
}


export const createInvitePostAPI = async (payload) => {
    let apiUrl = `${config.service.serverUri}${config.service.project.ops}/${payload.projectid}/${config.service.project.invite}`
    let data = payload.payload;

    return new Promise((resolve, reject) => {
        http.post(apiUrl, data)
            .then(function (response) {
                if (httpCommon.getResponseStatus(response)) {
                    try {
                        resolve(response.data);
                    }
                    catch (error) {
                        console.log(error);
                    }
                }
                else {
                    reject(response.data);
                }
            })
            .catch(function (error) {
                reject(error.response);
            });
    });
}

export const deleteInviteAPI = async (payload) => {
    let apiUrl = `${config.service.serverUri}${config.service.project.ops}/${payload.projectid}/${config.service.project.invite}/${payload.workflowid}`

    return new Promise((resolve, reject) => {
        http.delete(apiUrl)
            .then(function (response) {
                if (httpCommon.getResponseStatus(response)) {
                    try {
                        resolve(response.data);
                    }
                    catch (error) {
                        console.log(error);
                    }
                }
                else {
                    reject(response.data);
                }
            })
            .catch(function (error) {
                reject(error.response);
            });
    });
}

export const acceptInvitePutAPI = async (payload) => {
    let apiUrl = `${config.service.serverUri}${config.service.project.ops}/${payload.projectid}/${config.service.project.invite}/${payload.workflowid}/${config.service.project.acceptInvite}`
    let data = payload.payload;

    return new Promise((resolve, reject) => {
        http.put(apiUrl, data)
            .then(function (response) {
                if (httpCommon.getResponseStatus(response)) {
                    try {
                        resolve(response.data);
                    }
                    catch (error) {
                        console.log(error);
                    }
                }
                else {
                    reject(response.data);
                }
            })
            .catch(function (error) {
                reject(error.response);
            });
    });
}

export const rejectInvitePutAPI = async (payload) => {
    let apiUrl = `${config.service.serverUri}${config.service.project.ops}/${payload.projectid}/${config.service.project.invite}/${payload.workflowid}/${config.service.project.rejectInvite}`
    let data = payload.payload;

    return new Promise((resolve, reject) => {
        http.put(apiUrl, data)
            .then(function (response) {
                if (httpCommon.getResponseStatus(response)) {
                    try {
                        resolve(response.data);
                    }
                    catch (error) {
                        console.log(error);
                    }
                }
                else {
                    reject(response.data);
                }
            })
            .catch(function (error) {
                reject(error.response);
            });
    });
}

export const onHoldInvitePutAPI = async (payload) => {
    let apiUrl = `${config.service.serverUri}${config.service.project.ops}/${payload.projectid}/${config.service.project.invite}/${payload.workflowid}/${config.service.project.onHoldInvite}`
    let data = payload.payload;

    return new Promise((resolve, reject) => {
        http.put(apiUrl, data)
            .then(function (response) {
                if (httpCommon.getResponseStatus(response)) {
                    try {
                        resolve(response.data);
                    }
                    catch (error) {
                        console.log(error);
                    }
                }
                else {
                    reject(response.data);
                }
            })
            .catch(function (error) {
                reject(error.response);
            });
    });
}

export const withdrawInviteAPI = async (payload) => {
    let apiUrl = `${config.service.serverUri}${config.service.project.ops}/${payload.projectid}/${config.service.project.invite}/${payload.workflowid}/${config.service.project.withdrawInvite}`
    let data = payload.payload;

    return new Promise((resolve, reject) => {
        http.put(apiUrl, data)
            .then(function (response) {
                if (httpCommon.getResponseStatus(response)) {
                    try {
                        resolve(response.data);
                    }
                    catch (error) {
                        console.log(error);
                    }
                }
                else {
                    reject(response.data);
                }
            })
            .catch(function (error) {
                reject(error.response);
            });
    });
}