import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as oidcReducer } from 'redux-oidc';

import LoginReducer from './components/login/redux/LoginReducer';
import AppCommonReducer from './components/common/redux/AppCommonReducer'
import HomePageReducer from './components/home/redux/Reducer';
import ProjectReducer from './components/projects/redux/Reducer';
import OrganizationReducer from './components/OrganizationProfile/redux/Reducer';
import PublicReducer from './components/public/redux/Reducer';
import SearchReducer from './components/Search/redux/Reducer';
import ReportsReducer from './components/reports/redux/Reducer';


const rootReducer = (history) => combineReducers({
    router: connectRouter(history),
    App : AppCommonReducer,
    oidc: oidcReducer,
    LoginReducer: LoginReducer,
    HomePageprops: HomePageReducer,
    ProjectReducer: ProjectReducer,
    OrganizationReducer: OrganizationReducer,
    PublicReducer: PublicReducer,
    SearchReducer: SearchReducer,
    ReportsReducer: ReportsReducer,
})

export default rootReducer;
