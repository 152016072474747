import http from './http';
import config from '../common/constants/AppConstants'

import * as httpCommon from './httpCommon'

export const GetOrganizationDetailsAPI = async () => {
    let apiUrl = `${config.service.serverUri}${config.service.organization.me}`
    return new Promise((resolve, reject) => {
        http.get(apiUrl)
            .then(function (response) {
                if (httpCommon.getResponseStatus(response)) {
                    try {
                        resolve(response.data);
                    }
                    catch (error) {
                        console.log(error);
                    }
                }
                else {
                    reject(response.data);
                }
            })
            .catch(function (error) {
                reject(error.response);
            });
    });
}

export const GetCorporateTypesAPI = async () => {
    let apiUrl = `${config.service.serverUri}${config.service.organization.corp_types}`
    return new Promise((resolve, reject) => {
        http.get(apiUrl)
            .then(function (response) {
                if (httpCommon.getResponseStatus(response)) {
                    try {
                        resolve(response.data);
                    }
                    catch (error) {
                        console.log(error);
                    }
                }
                else {
                    reject(response.data);
                }
            })
            .catch(function (error) {
                reject(error.response);
            });
    });
}

export const GetNGOTypesAPI = async () => {
    let apiUrl = `${config.service.serverUri}${config.service.organization.ngo_types}`
    return new Promise((resolve, reject) => {
        http.get(apiUrl)
            .then(function (response) {
                if (httpCommon.getResponseStatus(response)) {
                    try {
                        resolve(response.data);
                    }
                    catch (error) {
                        console.log(error);
                    }
                }
                else {
                    reject(response.data);
                }
            })
            .catch(function (error) {
                reject(error.response);
            });
    });
}

export const PutOrganizationDetailsAPI = async (organization) => {
    let apiUrl = `${config.service.serverUri}${config.service.organization.uri}` + `/` + organization.id;
    let payloadData = organization;
    return new Promise((resolve, reject) => {
        http.put(apiUrl, payloadData)
            .then(function (response) {
                if (httpCommon.getResponseStatus(response)) {
                    try {
                        resolve(response.data);
                    }
                    catch (error) {
                        console.log(error);
                    }
                }
                else {
                    reject(response.data);
                }
            })
            .catch(function (error) {
                reject(error.response);
            });
    });
}

export const GetOrganizationDocuments = async (orgid) => {
    let apiUrl = `${config.service.serverUri}${config.service.organization.uri}` + `/` + orgid + `${config.service.organization.documents}`;
    return new Promise((resolve, reject) => {
        http.get(apiUrl)
            .then(function (response) {
                if (httpCommon.getResponseStatus(response)) {
                    try {
                        resolve(response.data);
                    }
                    catch (error) {
                        console.log(error);
                    }
                }
                else {
                    reject(response.data);
                }
            })
            .catch(function (error) {
                reject(error.response);
            });
    });
}

export const PostOrganizationDocument = async (orgid, document) => {
    let apiUrl = `${config.service.serverUri}${config.service.organization.uri}` + `/` + orgid + `${config.service.organization.documents}`;
    let payloadData = document;
    return new Promise((resolve, reject) => {
        http.post(apiUrl, payloadData)
            .then(function (response) {
                if (httpCommon.getResponseStatus(response)) {
                    try {
                        resolve(response.data);
                    }
                    catch (error) {
                        console.log(error);
                    }
                }
                else {
                    reject(response.data);
                }
            })
            .catch(function (error) {
                reject(error.response);
            });
    });
}

export const DeleteOrganizationDocument = async (orgid, documentid) => {
    let apiUrl = `${config.service.serverUri}${config.service.organization.uri}` + `/` + orgid + `${config.service.organization.documents}` + `/document/` + documentid;
    let payloadData = document;
    return new Promise((resolve, reject) => {
        http.delete(apiUrl)
            .then(function (response) {
                if (httpCommon.getResponseStatus(response)) {
                    try {
                        resolve(response.data);
                    }
                    catch (error) {
                        console.log(error);
                    }
                }
                else {
                    reject(response.data);
                }
            })
            .catch(function (error) {
                reject(error.response);
            });
    });
}

export const checkpublicurlAvailability = async (name) => {
    let apiUrl = `${config.service.serverUri}${config.service.organization.checkurlavailability}`
    return new Promise((resolve, reject) => {
        http.get(apiUrl, { params: { name: name } })
            .then(function (response) {
                if (httpCommon.getResponseStatus(response)) {
                    try {
                        resolve(response.data);
                    }
                    catch (error) {
                        console.log(error);
                    }
                }
                else {
                    reject(response.data);
                }
            })
            .catch(function (error) {
                reject(error.response);
            });
    });
}

export const SearchOrganizationsAPI = async (searchParams) => {
    let apiUrl = `${config.service.serverUri}${config.service.organization.search}`;

    let jsonstring = JSON.stringify(searchParams);
    return new Promise((resolve, reject) => {
        http.get(apiUrl, { params: { filter: jsonstring } })
            .then(function (response) {
                if (httpCommon.getResponseStatus(response)) {
                    try {
                        resolve(response.data);
                    }
                    catch (error) {
                        console.log(error);
                    }
                }
                else {
                    reject(response.data);
                }
            })
            .catch(function (error) {
                reject(error.response);
            });
    });
}