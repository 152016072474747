import types from './types';

export const fetchOwnReportsSummary = (value) => {

    return {
        type: types.FETCH_OWN_REPORTS_SUMMARY,
        value: value
    }
};

export const setOwnReportsSummary = (value) => {

    return {
        type: types.SET_OWN_REPORTS_SUMMARY,
        value: value
    }
};

export const resetOwnReportsSummary = (value) => {

    return {
        type: types.RESET_OWN_REPORTS_SUMMARY,
        value: value
    }
};

export const fetchProjectList = (value) => {

    return {
        type: types.FETCH_PROJECT_LIST,
        value: value
    }
};

export const setProjectList = (value) => {

    return {
        type: types.SET_PROJECT_LIST,
        value: value
    }
};

export const resetProjectList = (value) => {

    return {
        type: types.RESET_PROJECT_LIST,
        value: value
    }
};

export const createReport = (value) => {

    return {
        type: types.CREATE_REPORT,
        value: value
    }
};

export const editReport = (value) => {

    return {
        type: types.EDIT_REPORT,
        value: value
    }
};

export const deleteReport = (value) => {

    return {
        type: types.DELETE_REPORT,
        value: value
    }
};

export const fetchReportDetailed = (value) => {

    return {
        type: types.FETCH_REPORT_DETAILED,
        value: value
    }
};

export const setReportDetailed = (value) => {

    return {
        type: types.SET_REPORT_DETAILED,
        value: value
    }
};

export const resetReportDetailed = (value) => {

    return {
        type: types.RESET_REPORT_DETAILED,
        value: value
    }
};

export const fetchProjectTasks = (value) => {

    return {
        type: types.FETCH_PROJECT_TASKS,
        value: value
    }
};

export const setProjectTasks = (value) => {

    return {
        type: types.SET_PROJECT_TASKS,
        value: value
    }
};

export const resetProjectTasks = (value) => {

    return {
        type: types.RESET_PROJECT_TASKS,
        value: value
    }
};

export const reportPublish = (value) => {

    return {
        type: types.REPORT_PUBLISH,
        value: value
    }
};

export const reportUnPublish = (value) => {

    return {
        type: types.REPORT_UNPUBLISH,
        value: value
    }
};

export const resetStore = (value) => {
    return {
        type: types.RESET_STORE,
        value: value
    }    
}

const ReportsActions = {
    fetchOwnReportsSummary,
    setOwnReportsSummary,
    resetOwnReportsSummary,
    fetchProjectList,
    setProjectList,
    resetProjectList,
    createReport,
    editReport,
    deleteReport,
    fetchReportDetailed,
    setReportDetailed,
    resetReportDetailed,
    fetchProjectTasks,
    setProjectTasks,
    resetProjectTasks,
    reportPublish,
    reportUnPublish,
    resetStore,
};

export default ReportsActions;