import axios from 'axios';
import config from '../common/constants/AppConstants'

export const getOrganizationPublicQueryAPI = async (orgPublicName) => {
    let tokenUrl = `${config.service.serverUri}${config.service.public.organization}` + `/` + orgPublicName

    return new Promise((resolve, reject) => {
        axios.get(tokenUrl)
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error.response);
            })
    });
};

export const getAllPublicProjectsAPI = async (name) => {
    let tokenUrl = `${config.service.serverUri}${config.service.public.projects}`
    return new Promise((resolve, reject) => {
        axios.get(tokenUrl, { params: { orgPublicName: name, queryParam: "ALL" } })
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error.response);
            })
    });
};

export const getActivePublicProjectsAPI = async (name) => {
    let tokenUrl = `${config.service.serverUri}${config.service.public.projects}`
    return new Promise((resolve, reject) => {
        axios.get(tokenUrl, { params: { orgPublicName: name, queryParam: "ACTIVE" } })
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error.response);
            })
    });
};

export const getCompletedPublicProjectsAPI = async (name) => {
    let tokenUrl = `${config.service.serverUri}${config.service.public.projects}`
    return new Promise((resolve, reject) => {
        axios.get(tokenUrl, { params: { orgPublicName: name, queryParam: "COMPLETE" } })
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error.response);
            })
    });
};

export const getPublicOrganizationMediaQueryAPI = async (orgPublicName) => {
    let tokenUrl = `${config.service.serverUri}${config.service.public.organization}` + `/` + orgPublicName + `/` + `${config.service.public.media}`

    return new Promise((resolve, reject) => {
        axios.get(tokenUrl)
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error.response);
            })
    });
};

export const getPublicOrganizationBoardMembersQueryAPI = async (orgPublicName) => {
    let tokenUrl = `${config.service.serverUri}${config.service.public.organization}` + `/` + orgPublicName + `/` + `${config.service.public.team}`

    return new Promise((resolve, reject) => {
        axios.get(tokenUrl)
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error.response);
            })
    });
};
