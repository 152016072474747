const FETCH_EVENTS_API = "FETCH_EVENTS_API";
const SET_EVENTS = "SET_EVENTS";
const REGISTER_EVENT_USER = "REGISTER_EVENT_USER";
const FETCH_LATEST_PROJECTS = "FETCH_LATEST_PROJECTS";
const SET_LATEST_PROJECTS = "SET_LATEST_PROJECTS";

const types = {
    FETCH_EVENTS_API,
    SET_EVENTS,
    REGISTER_EVENT_USER,
    FETCH_LATEST_PROJECTS,
    SET_LATEST_PROJECTS,
};

export default types;