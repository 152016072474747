import types from './types';

export const SetOrganizationDetails = (value) => {

    return {
        type: types.SET_ORGANIZATION_DETAILS,
        value: value
    }
};

export const FetchOrganizationDetails = (value) => {

    return {
        type: types.FETCH_ORGANIZATION_DETAILS,
        value: value
    }
};

export const setLicense = (value) => {

    return {
        type: types.SET_LICENSE,
        value: value
    }
};

export const PutOrganizationDetails = (value) => {

    return {
        type: types.PUT_ORGANIZATION_DETAILS,
        value: value
    }
};

export const fetchCoroprateTypes = (value) => {

    return {
        type: types.FETCH_CORP_TYPES,
        value: value
    }
};

export const setCoroprateTypes = (value) => {

    return {
        type: types.SET_CORP_TYPES,
        value: value
    }
};
export const fetchNGOTypes = (value) => {

    return {
        type: types.FETCH_NGO_TYPES,
        value: value
    }
};

export const setNGOTypes = (value) => {

    return {
        type: types.SET_NGO_TYPES,
        value: value
    }
};

export const fetchNotifications = (value) => {

    return {
        type: types.FETCH_NOTIFICATIONS,
        value: value
    }
};

export const setNotifications = (value) => {

    return {
        type: types.SET_NOTIFICATIONS,
        value: value
    }
};

export const deleteNotification = (value) => {

    return {
        type: types.DELETE_NOTIFICATION,
        value: value
    }
};

export const markNotificationRead = (value) => {

    return {
        type: types.MARK_NOTIFICATION_READ,
        value: value
    }
};

export const deleteNotificationStore = (value) => {

    return {
        type: types.DELETE_NOTIFICATION_STORE,
        value: value
    }
};

export const markNotificationReadStore = (value) => {

    return {
        type: types.MARK_NOTIFICATION_READ_STORE,
        value: value
    }
};

export const resetStore = (value) => {
    return {
        type: types.RESET_STORE,
        value: value
    }    
}

const ProjectActions = {
    SetOrganizationDetails,
    FetchOrganizationDetails,
    PutOrganizationDetails,
    fetchCoroprateTypes,
    setCoroprateTypes,
    fetchNGOTypes,
    setNGOTypes,
    fetchNotifications,
    setNotifications,
    deleteNotification,
    markNotificationRead,
    deleteNotificationStore,
    markNotificationReadStore,
    resetStore,
};

export default ProjectActions;