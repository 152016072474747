import React, { Suspense } from 'react';
import { connect } from 'react-redux';
import './styles/App.scss';
import { ConnectedRouter } from 'connected-react-router';
import { Switch } from "react-router-dom";

import { ToastContainer, cssTransition, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap';
// import './App.sass';

import * as routingConstants from './components/common/constants/routingConstants'

import AppCommonActions from './components/common/redux/AppCommonActions';

import LayoutRoute from './components/common/layouts/LayoutRoute';
import EmptyLayout from './components/common/layouts/EmptyLayout';
import HomepageLayout from './components/common/layouts/HomepageLayout';
const AppLayout = React.lazy(() => import('./components/common/layouts/AppLayout'));
const PublicAppLayout = React.lazy(() => import('./components/common/layouts/PublicAppLayout'));

// import HomeContainer from './components/home/HomeContainer';
const HomeContainer = React.lazy(() => import('./components/home/HomeContainer'));

const LoginContainer = React.lazy(() => import('./components/login/LoginContainer'));
const RegisterContainer = React.lazy(() => import('./components/login/RegisterContainer'));
const LoginHomeContainer = React.lazy(() => import('./components/loginhome/LoginHomeContainer'));
const SelfProjectsContainer = React.lazy(() => import('./components/projects/SelfProjectsContainer'));
const GenericCreateProjectContainer = React.lazy(() => import('./components/projects/GenericCreateProjectContainer'));
const ProjectDetailsContainer = React.lazy(() => import('./components/projects/ProjectDetailsContainer'));
// const ProjectTasksContainer = React.lazy(() => import('./components/projects/ProjectTasksContainer'));
// const ManageInvitesContainer = React.lazy(() => import('./components/projects/ManageInvitesContainer'));
const CreateInviteContainer = React.lazy(() => import('./components/projects/CreateInviteContainer'));
const PublicOrgHomeContainer = React.lazy(() => import('./components/public/PublicOrgHomeContainer'));
const PublicOrgActiveProjectsContainer = React.lazy(() => import('./components/public/PublicOrgActiveProjectsContainer'));
const PublicOrgCompleteProjectsContainer = React.lazy(() => import('./components/public/PublicOrgCompleteProjectsContainer'));
const PublicOrgLegalContainer = React.lazy(() => import('./components/public/PublicOrgLegalContainer'));
const PublicOrgOurTeamContainer = React.lazy(() => import('./components/public/PublicOrgOurTeamContainer'));
const PublicOrgPhotosContainer = React.lazy(() => import('./components/public/PublicOrgPhotosContainer'));
const SearchContainer = React.lazy(() => import('./components/Search/SearchContainer'));
const OrganizationProfileContainer = React.lazy(() => import('./components/OrganizationProfile/OrganizationProfileContainer'));
const TermsAndConditionsContainer = React.lazy(() => import('./components/common/TermsContainer'));
const PrivacyPolicyContainer = React.lazy(() => import('./components/common/PrivacyContainer'));
const PricingContainer = React.lazy(() => import('./components/home/PricingContainer'));
const InvitedProjectsContainer = React.lazy(() => import('./components/projects/InvitedProjectsContainer'));
const IncomingInvitesContainer = React.lazy(() => import('./components/projects/IncomingInvitesContainer'));
const OutgoingInvitesContainer = React.lazy(() => import('./components/projects/OutgoingInvitesContainer'));
const ReportsContainer = React.lazy(() => import('./components/reports/ReportsContainer'));
const EditReportContainer = React.lazy(() => import('./components/reports/EditReportContainer'));
const ViewReportContainer = React.lazy(() => import('./components/reports/ViewReportContainer'));
import CSRLoader from './common/UI/CSRLoader'

const NotFoundComponent = React.lazy(() => import('./components/common/components/NotFoundComponent'));
const CallbackPage = React.lazy(() => import('./components/callback/CallbackPage'));


const fadeInFadeOut = cssTransition({
    enter: 'zoomIn',
    exit: 'zoomOut',
});

class App extends React.Component {

    componentDidMount() {

    }


    render() {
        let commonProps = {
            AppCommonprops: this.props.AppCommonprops,
            history: this.props.history,
            disableLoader: this.props.disableLoader,
            enableLoader: this.props.enableLoader,
        }

        return (
            <div className="App">
                <Suspense fallback={<CSRLoader />}>
                    <ConnectedRouter history={this.props.history}>
                        <Switch>
                            <LayoutRoute
                                exact
                                path="/"
                                layout={HomepageLayout}
                                component={HomeContainer}
                                {...commonProps}
                            />
                            <LayoutRoute
                                exact
                                path={routingConstants.CALLBACK}
                                layout={EmptyLayout}
                                component={CallbackPage}
                                {...commonProps}
                            />
                            <LayoutRoute
                                exact
                                path={routingConstants.TERMS_AND_CONDITIONS}
                                layout={EmptyLayout}
                                component={TermsAndConditionsContainer}
                                {...commonProps}
                            />
                            <LayoutRoute
                                exact
                                path={routingConstants.PRIVACY_POLICY}
                                layout={EmptyLayout}
                                component={PrivacyPolicyContainer}
                                {...commonProps}
                            />
                            <LayoutRoute
                                exact
                                path={routingConstants.PRICING}
                                layout={EmptyLayout}
                                component={PricingContainer}
                                {...commonProps}
                            />
                            <LayoutRoute
                                exact
                                path={routingConstants.LOGIN}
                                layout={EmptyLayout}
                                component={LoginContainer}
                                {...commonProps}
                            />
                            <LayoutRoute
                                exact
                                path={routingConstants.REGISTER}
                                layout={EmptyLayout}
                                component={RegisterContainer}
                                {...commonProps}
                            />
                            <LayoutRoute
                                exact
                                path={routingConstants.LOGIN_HOME}
                                layout={AppLayout}
                                component={LoginHomeContainer}
                                {...commonProps}
                            />
                            <LayoutRoute
                                exact
                                path={routingConstants.PROJECTS_OWN}
                                layout={AppLayout}
                                component={SelfProjectsContainer}
                                {...commonProps}
                            />
                            {/* <LayoutRoute
                                exact
                                path={routingConstants.MANAGE_PROJECT_INVITES}
                                layout={AppLayout}
                                component={InvitedProjectsContainer}
                                {...commonProps}
                            /> */}
                            <LayoutRoute
                                exact
                                path={routingConstants.MANAGE_INCOMING_PROJECT_INVITES}
                                layout={AppLayout}
                                component={IncomingInvitesContainer}
                                {...commonProps}
                            />
                            <LayoutRoute
                                exact
                                path={routingConstants.MANAGE_OUTGOING_PROJECT_INVITES}
                                layout={AppLayout}
                                component={OutgoingInvitesContainer}
                                {...commonProps}
                            />
                            <LayoutRoute
                                exact
                                path={routingConstants.PROJECTS_CREATE}
                                layout={AppLayout}
                                component={GenericCreateProjectContainer}
                                {...commonProps}
                            />
                            <LayoutRoute
                                exact
                                path={routingConstants.PROJECT_DETAILS}
                                layout={AppLayout}
                                component={ProjectDetailsContainer}
                                {...commonProps}
                            />
                            <LayoutRoute
                                exact
                                path={routingConstants.PROJECT_EDIT}
                                layout={AppLayout}
                                component={GenericCreateProjectContainer}
                                {...commonProps}
                            />
                            {/* <LayoutRoute
                                exact
                                path={routingConstants.PROJECT_PHASES}
                                layout={AppLayout}
                                component={ProjectTasksContainer}
                                {...commonProps}
                            /> */}
                            {/* <LayoutRoute
                                exact
                                path={routingConstants.PROJECT_INVITES}
                                layout={AppLayout}
                                component={ManageInvitesContainer}
                                {...commonProps}
                            /> */}
                            <LayoutRoute
                                exact
                                path={routingConstants.CREATE_PROJECT_INVITE}
                                layout={AppLayout}
                                component={CreateInviteContainer}
                                {...commonProps}
                            />
                            <LayoutRoute
                                exact
                                path={routingConstants.ORGANIZATION_SEARCH}
                                layout={AppLayout}
                                component={SearchContainer}
                                {...commonProps}
                            />
                            <LayoutRoute
                                exact
                                path={routingConstants.ORGANIZATION_PROFILE}
                                layout={AppLayout}
                                component={OrganizationProfileContainer}
                                {...commonProps}
                            />
                            <LayoutRoute
                                exact
                                path={routingConstants.PUBLIC_ORGANIZATION_ACTIVE_PROJECTS}
                                layout={PublicAppLayout}
                                component={PublicOrgActiveProjectsContainer}
                                {...commonProps}
                            />
                            <LayoutRoute
                                exact
                                path={routingConstants.PUBLIC_ORGANIZATION_COMPLETE_PROJECTS}
                                layout={PublicAppLayout}
                                component={PublicOrgCompleteProjectsContainer}
                                {...commonProps}
                            />                            
                            <LayoutRoute
                                exact
                                path={routingConstants.PUBLIC_ORGANIZATION_MEDIA}
                                layout={PublicAppLayout}
                                component={PublicOrgPhotosContainer}
                                {...commonProps}
                            />
                            <LayoutRoute
                                exact
                                path={routingConstants.PUBLIC_ORGANIZATION_LEGAL}
                                layout={PublicAppLayout}
                                component={PublicOrgLegalContainer}
                                {...commonProps}
                            />
                            <LayoutRoute
                                exact
                                path={routingConstants.PUBLIC_ORGANIZATION_OURTEAM}
                                layout={PublicAppLayout}
                                component={PublicOrgOurTeamContainer}
                                {...commonProps}
                            />
                            <LayoutRoute
                                exact
                                path={routingConstants.PUBLIC_ORGANIZATION_HOME}
                                layout={PublicAppLayout}
                                component={PublicOrgHomeContainer}
                                {...commonProps}
                            />
                            <LayoutRoute
                                exact
                                path={routingConstants.REPORTS_OWN}
                                layout={AppLayout}
                                component={ReportsContainer}
                                {...commonProps}
                            />
                            <LayoutRoute
                                exact
                                path={routingConstants.REPORT_EDIT}
                                layout={AppLayout}
                                component={EditReportContainer}
                                {...commonProps}
                            />
                            <LayoutRoute
                                exact
                                path={routingConstants.REPORT_VIEW}
                                layout={AppLayout}
                                component={ViewReportContainer}
                                {...commonProps}
                            />
                            <LayoutRoute
                                path={routingConstants.NOT_FOUND}
                                layout={EmptyLayout}
                                component={props => (
                                    <NotFoundComponent {...props} />
                                )}
                            />
                            <LayoutRoute
                                layout={EmptyLayout}
                                component={props => (
                                    <NotFoundComponent {...props} />
                                )}
                            />
                        </Switch>
                    </ConnectedRouter>
                    <ToastContainer
                        transition={fadeInFadeOut}
                        newestOnTop
                        draggable={false}
                        toastClassName="ngo_popup_container"
                        hideProgressBar
                        autoClose={2000}
                    />
                </Suspense>
            </div>

        )
    }
}

const mapStateToProps = (state) => {
    return {
        AppCommonprops: state.App,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        logoutUser: () => dispatch(AppCommonActions.logoutUser()),
        setIsLoading: () => dispatch(AppCommonActions.setIsLoading(input)),
        disableLoader: () => dispatch(AppCommonActions.disableLoader()),
        enableLoader: () => dispatch(AppCommonActions.enableLoader()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
