import React, { Component } from 'react';
import * as routingConstants from './constants/routingConstants'
import logomain from '../../images/logo-main.png'
import facebookimg from '../../images/new/fb_img.png'
import twiterimg from '../../images/new/twitter_img.png'
import linkedinimg from '../../images/new/in_img.png'
import instagramimg from '../../images/new/insta_img.png'
import youtubeimg from '../../images/new/youtube.png'

class Footer extends Component {
    render() {
        return (
            <section id="footer">
                <hr></hr>
                <footer>
                    <div className="row">
                        <div className='row socialicons'>
                            <span>
                                <a href="https://fb.me/bgcsrnexus" target="_blank"><img src={facebookimg} alt="" ></img></a>
                                <a href="https://twitter.com/csrnexus" target="_blank"><img src={twiterimg} alt="" ></img></a>
                                <a href="https://www.linkedin.com/company/csrnexus/" target="_blank"><img src={linkedinimg} alt="" ></img></a>
                                <a href="https://www.instagram.com/csrnexus" target="_blank"><img src={instagramimg} alt="" ></img></a>
                                <a href="https://www.youtube.com/channel/UCoBhbWfSCNT3fqjKoz2LeIA" target="_blank"><img src={youtubeimg} alt="" ></img></a>
                            </span>
                        </div>
                        <p>© All rights Reserved - BG CSRNexus LLP - 2023,  Contact us: info@csrnexus.com</p>
                    </div>
                    <div className='termprivacy'>
                        <div>
                            <a href={routingConstants.TERMS_AND_CONDITIONS} target='_blank'> (Terms and Conditions) </a>
                        </div>
                        <div>
                            <a href={routingConstants.PRIVACY_POLICY} target='_blank'> (Privacy Policy) </a>
                        </div>
                    </div>
                </footer>
            </section>
        )
    }
}

export default Footer;