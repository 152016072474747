import { put, takeLatest, call, take } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { push } from 'connected-react-router';
import types from './types';
import * as PublicAPIService from '../../../services/PublicAPIService'
import * as Actions from './Actions'
import * as routingConstants from '../../common/constants/routingConstants'


export function* getOrganizationPublicInfo(action) {
    try {
        let organization = yield call(PublicAPIService.getOrganizationPublicQueryAPI, action.value);
        yield put(Actions.setOrganizationPublicInfo(organization));

    } catch (error) {
        toast.error("Error fetching organization.", 
            { autoClose: false });
        yield put(push(routingConstants.NOT_FOUND));
    }
}

export function* fetchActivePublicProjects(action) {
    try {
        let projects = yield call(PublicAPIService.getActivePublicProjectsAPI, action.value);
        yield put(Actions.setActivePublicProjects(projects));

    } catch (error) {
        toast.error("Error fetching projects.", 
            { autoClose: false });
    }
}

export function* fetchCompletePublicProjects(action) {
    try {
        let projects = yield call(PublicAPIService.getCompletedPublicProjectsAPI, action.value);
        yield put(Actions.setCompletePublicProjects(projects));

    } catch (error) {
        toast.error("Error fetching projects.", 
            { autoClose: false });
    }
}

export function* fetchOrganizationMedia(action) {
    try {
        let projects = yield call(PublicAPIService.getPublicOrganizationMediaQueryAPI, action.value);
        yield put(Actions.setOrganizationMedia(projects));

    } catch (error) {
        toast.error("Error fetching media.", 
            { autoClose: false });
    }
}

export function* fetchOrganizationTeam(action) {
    try {
        let projects = yield call(PublicAPIService.getPublicOrganizationBoardMembersQueryAPI, action.value);
        yield put(Actions.setOrganizationTeam(projects));

    } catch (error) {
        toast.error("Error fetching team.", 
            { autoClose: false });
    }
}

export const PublicOperations = [
    takeLatest(types.GET_ORGANIZATION_PUBLIC_INFO, getOrganizationPublicInfo),
    takeLatest(types.GET_ACTIVE_PUBLIC_PROJECTS, fetchActivePublicProjects),
    takeLatest(types.GET_COMPLETE_PUBLIC_PROJECTS, fetchCompletePublicProjects),
    takeLatest(types.GET_ORGANIZATION_MEDIA, fetchOrganizationMedia),
    takeLatest(types.GET_ORGANIZATION_TEAM, fetchOrganizationTeam),
]
