import React from 'react';
import ReactDOM from 'react-dom';

// import './index.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'font-awesome/css/font-awesome.min.css';

import App from './App';
// import reportWebVitals from './reportWebVitals';
// import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
// import { routerMiddleware } from 'connected-react-router';
// import { createStore, compose, applyMiddleware } from 'redux';
// import createSagaMiddleware from 'redux-saga';
import { OidcProvider } from 'redux-oidc';

import rootSaga from './Saga';
// import { createBrowserHistory } from 'history';
import { persistStore, persistReducer } from 'redux-persist';
import { createUserManager, loadUser } from "redux-oidc";
// import storage from 'redux-persist/lib/storage';
import { PersistGate } from 'redux-persist/integration/react';
// import { encryptTransform } from 'redux-persist-transform-encrypt';
// import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
// import createRootReducer from './reducers'
import userManager from './common/utility/userManager';
import { store, sagaMiddlerware }  from './store';
import history from './history';

// const history = createBrowserHistory({ basename: '/' });
// const sagaMiddlerware = createSagaMiddleware();

// const encryptor = encryptTransform({
//     secretKey: 'my-super-secret-key',
//     onError: function (error) {
//         console.log('ERROR IN REDUX PERSIST');
//     },
// })

// const persistConfig = {
//     key: 'root',
//     storage,
//     transforms: [encryptor],
//     stateReconciler: autoMergeLevel2,
//     blacklist: ['router']
// } //encryptor

// const persistedReducer = persistReducer(persistConfig, createRootReducer(history))

// const store = createStore(
//     persistedReducer,
//     compose(applyMiddleware(sagaMiddlerware, routerMiddleware(history)),
//         window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
//     )
// );

loadUser(store, userManager);

let persistor = persistStore(store);
sagaMiddlerware.run(rootSaga);


ReactDOM.render(
    <Provider store={store}>
        <OidcProvider store={store} userManager={userManager}>
            <PersistGate loading={null} persistor={persistor}>
                <App history={history} />
            </PersistGate>
        </OidcProvider>
    </Provider>,
    document.getElementById('root')
);

if (module.hot) {
    module.hot.accept()
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
