import http from './http';
import config from '../common/constants/AppConstants'

import * as httpCommon from './httpCommon'


export const GetFocusAreaForOrgAPI = async () => {
    let apiUrl = `${config.service.serverUri}${config.service.focusarea.uri}`
    return new Promise((resolve, reject) => {
        http.get(apiUrl)
            .then(function (response) {
                if(httpCommon.getResponseStatus(response)) {
                    try {
                        resolve(response.data);
                    }
                    catch(error) {
                        console.log(error);
                    }
                }
                else {
                    reject(response.data);
                }
            })
            .catch(function (error) {
                reject(error.response);
            });
    });
}