import axios from 'axios';
import config from '../common/constants/AppConstants'


export const RegisterUserAPI = async (user) => {
    let tokenUrl = `${config.service.serverUri}${config.service.authentication.signup}`
    return new Promise((resolve, reject) => {
        axios.post(tokenUrl, user)
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error.response);
            })
    });
};
