
import { routerMiddleware } from 'connected-react-router';
import { createStore, compose, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import createSagaMiddleware from 'redux-saga';
import { encryptTransform } from 'redux-persist-transform-encrypt';
import { createUserManager, loadUser } from "redux-oidc";

import rootSaga from './Saga';
import createRootReducer from './reducers'
// import reducers from "./reducers";
import userManager from "./common/utility/userManager";
import history from './history'


export const sagaMiddlerware = createSagaMiddleware();


const encryptor = encryptTransform({
    secretKey: 'my-super-secret-key',
    onError: function (error) {
        console.log('ERROR IN REDUX PERSIST');
    },
})

const persistConfig = {
    key: 'root',
    storage,
    transforms: [encryptor],
    stateReconciler: autoMergeLevel2,
    blacklist: ['router']
} //encryptor

const persistedReducer = persistReducer(persistConfig, createRootReducer(history))

export const store = createStore(
    persistedReducer,
    compose(applyMiddleware(sagaMiddlerware, routerMiddleware(history)),
        window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
    )
);