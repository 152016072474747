import CryptoJS from 'crypto-js';
import { ENCRYPTION_KEY, ENCRYPTION_SALT, ENCRYPTION_IV } from '../constants/AppConstants';

const keySize = 128;
const iterations = 1000;

export const isNullOrEmpty = (inputValue) => {
    if (inputValue && inputValue.toString().trim().length !== 0) {
        return false;
    } 
    return true;
}

export const validateEmpty = (value) => {
    let valid = false;
    if (value && value.length > 0) {
        valid = true;
    }
    return valid;
}

export const trim = (value) => {
    return value.trim();
}


export const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export const validatePhoneNumber = (phone) => {
    const re = /^[0-9]\d{9}$/;
    return re.test(String(phone).toLowerCase());
}

export const encrypt = (value) => {
    return window.btoa(value);
}

export const decrypt = (value) => {
    return window.atob(value);
}

export const decryptAESString = (strValue) => {
    if (strValue) {
        try {
            //Creating the Vector Key
            var iv = CryptoJS.enc.Hex.parse(ENCRYPTION_IV);
            //Encoding the Password in from UTF8 to byte array
            var Pass = CryptoJS.enc.Utf8.parse(ENCRYPTION_KEY);
            //Encoding the Salt in from UTF8 to byte array
            var Salt = CryptoJS.enc.Utf8.parse(ENCRYPTION_SALT);
            //Creating the key in PBKDF2 format to be used during the decryption
            var key128Bits1000Iterations = CryptoJS.PBKDF2(Pass.toString(CryptoJS.enc.Utf8), Salt, { keySize: 128 / 32, iterations: 1000 });
            //Enclosing the test to be decrypted in a CipherParams object as supported by the CryptoJS libarary
            var cipherParams = CryptoJS.lib.CipherParams.create({
                ciphertext: CryptoJS.enc.Base64.parse(strValue)
            });
    
            //Decrypting the string contained in cipherParams using the PBKDF2 key
            var decrypted = CryptoJS.AES.decrypt(cipherParams, key128Bits1000Iterations, 
                { mode: CryptoJS.mode.CBC, iv: iv, padding: CryptoJS.pad.Pkcs7 });
            return decrypted.toString(CryptoJS.enc.Utf8);
            // let bytes = CryptoJS.AES.decrypt(strValue.toString(), ENCRYPTION_KEY);
            // return bytes.toString(CryptoJS.enc.Utf8);
        } catch (error) {
            return "";
        }
    } else {
        return "";
    }
}

export const encryptAESString = (strValue) => {
    if (strValue) {
        try {
            let salt = CryptoJS.enc.Utf8.parse(ENCRYPTION_SALT);
            let key = CryptoJS.PBKDF2(ENCRYPTION_KEY, salt, {
                keySize: keySize/32,
                iterations: iterations
            });
            let iv = CryptoJS.enc.Hex.parse(ENCRYPTION_IV);
            let encrypted = CryptoJS.AES.encrypt(strValue, key, 
                { mode: CryptoJS.mode.CBC, iv: iv, padding: CryptoJS.pad.Pkcs7 });
            // salt, iv will be hex 32 in length
            // append them to the ciphertext for use  in decryption
            return encrypted.toString();
        } catch (error) {
            return "";
        }
    } else {
        return "";
    }
}

export const getJsonIndex = (array, property, value) => {
    let index = -1;
    // function getIndex (name) {
    for (var i = 0; i < array.length; ++i) {
        if (array[i][property] === value) {
            index = i;
            break;
        }
    }
    return index;
}