const FETCH_OWNER_PROJECTS_SUMMARY = "FETCH_OWNER_PROJECTS_SUMMARY";
const SET_OWNER_PROJECTS_SUMMARY = "SET_OWNER_PROJECTS_SUMMARY";
const RESET_OWNER_PROJECTS_SUMMARY = "RESET_OWNER_PROJECTS_SUMMARY";
const FETCH_FOCUS_AREAS = "FETCH_FOCUS_AREAS";
const SET_FOCUS_AREAS = "SET_FOCUS_AREAS";
const PROJECT_CREATE = "PROJECT_CREATE";
const PROJECT_EDIT = "PROJECT_EDIT";
const PROJECT_DELETE = "PROJECT_DELETE";
const FETCH_PROJECT_TASKS = "FETCH_PROJECT_TASKS";
const SET_PROJECT_TASKS = "SET_PROJECT_TASKS";
const POST_PROJECT_TASKS = "POST_PROJECT_TASKS";
const FETCH_PROJECT_INFO_DETAILS = "FETCH_PROJECT_INFO_DETAILS";
const SET_PROJECT_INFO_DETAILS = "SET_PROJECT_INFO_DETAILS";
const RESET_PROJECT_INFO_DETAILS = "RESET_PROJECT_INFO_DETAILS";
const FETCH_PROJECT_REPORTS = "FETCH_PROJECT_REPORTS";
const SET_PROJECT_REPORTS = "SET_PROJECT_REPORTS";
const RESET_PROJECT_REPORTS = "RESET_PROJECT_REPORTS";
const FETCH_INVITES_BY_ORGANIZATION = "FETCH_INVITES_BY_ORGANIZATION";
const SET_INVITES_BY_ORGANIZATION = "SET_INVITES_BY_ORGANIZATION";
const RESET_INVITES_BY_ORGANIZATION = "RESET_INVITES_BY_ORGANIZATION";
const DELETE_INVITATION = "DELETE_INVITATION";
const ACCEPT_PROJECT_INVITE = "ACCEPT_PROJECT_INVITE";
const REJECT_PROJECT_INVITE = "REJECT_PROJECT_INVITE";
const ONHOLD_PROJECT_INVITE = "ONHOLD_PROJECT_INVITE";
const WITHDRAW_PROJECT_INVITE = "WITHDRAW_PROJECT_INVITE";
const FETCH_PROJECTLIST_FOR_INVITES = "FETCH_PROJECTLIST_FOR_INVITES";
const SET_PROJECTLIST_FOR_INVITES = "SET_PROJECTLIST_FOR_INVITES";
const RESET_PROJECTLIST_FOR_INVITES = "RESET_PROJECTLIST_FOR_INVITES";
const FETCH_INCOMING_INVITES_BY_ORGANIZATION = "FETCH_INCOMING_INVITES_BY_ORGANIZATION";
const SET_INCOMING_INVITES_BY_ORGANIZATION = "SET_INCOMING_INVITES_BY_ORGANIZATION";
const RESET_INCOMING_INVITES_BY_ORGANIZATION = "RESET_INCOMING_INVITES_BY_ORGANIZATION";
const FETCH_OUTGOING_INVITES_BY_ORGANIZATION = "FETCH_OUTGOING_INVITES_BY_ORGANIZATION";
const SET_OUTGOING_INVITES_BY_ORGANIZATION = "SET_OUTGOING_INVITES_BY_ORGANIZATION";
const RESET_OUTGOING_INVITES_BY_ORGANIZATION = "RESET_OUTGOING_INVITES_BY_ORGANIZATION";
const SEARCH_ORGANIZATIONS = "SEARCH_ORGANIZATIONS";
const SET_SEARCH_RESULT = "SET_SEARCH_RESULT";
const RESET_SEARCH_RESULT = "RESET_SEARCH_RESULT";
const FETCH_SCHEDULES_BY_PROJECT = "FETCH_SCHEDULES_BY_PROJECT";
const SET_SCHEDULE_FOR_PROJECT = "SET_SCHEDULE_FOR_PROJECT";
const UPDATE_SCHEDULE_FOR_PROJECT = "UPDATE_SCHEDULE_FOR_PROJECT";
const RESET_SCHEDULE_FOR_PROJECT = "RESET_SCHEDULE_FOR_PROJECT";
const SAVE_SCHEDULE_FOR_PROJECT = "SAVE_SCHEDULE_FOR_PROJECT";
const DELETE_SCHEDULE_FOR_PROJECT = "DELETE_SCHEDULE_FOR_PROJECT";
const DELETE_SCHEDULE_FROM_STORE = "DELETE_SCHEDULE_FROM_STORE";
const RESET_STORE = "RESET_STORE";

const types = {
    FETCH_OWNER_PROJECTS_SUMMARY,
    SET_OWNER_PROJECTS_SUMMARY,
    RESET_OWNER_PROJECTS_SUMMARY,
    FETCH_FOCUS_AREAS,
    SET_FOCUS_AREAS,
    PROJECT_CREATE,
    PROJECT_EDIT,
    PROJECT_DELETE,
    FETCH_PROJECT_TASKS,
    SET_PROJECT_TASKS,
    POST_PROJECT_TASKS,
    FETCH_PROJECT_INFO_DETAILS,
    SET_PROJECT_INFO_DETAILS,
    RESET_PROJECT_INFO_DETAILS,
    FETCH_PROJECT_REPORTS,
    SET_PROJECT_REPORTS,
    RESET_PROJECT_REPORTS,
    FETCH_INVITES_BY_ORGANIZATION,
    SET_INVITES_BY_ORGANIZATION,
    RESET_INVITES_BY_ORGANIZATION,
    DELETE_INVITATION,
    ACCEPT_PROJECT_INVITE,
    REJECT_PROJECT_INVITE,
    ONHOLD_PROJECT_INVITE,
    WITHDRAW_PROJECT_INVITE,
    FETCH_PROJECTLIST_FOR_INVITES,
    SET_PROJECTLIST_FOR_INVITES,
    RESET_PROJECTLIST_FOR_INVITES,
    SEARCH_ORGANIZATIONS,
    SET_SEARCH_RESULT,
    RESET_SEARCH_RESULT,
    FETCH_INCOMING_INVITES_BY_ORGANIZATION,
    SET_INCOMING_INVITES_BY_ORGANIZATION,
    RESET_INCOMING_INVITES_BY_ORGANIZATION,
    FETCH_OUTGOING_INVITES_BY_ORGANIZATION,
    SET_OUTGOING_INVITES_BY_ORGANIZATION,
    RESET_OUTGOING_INVITES_BY_ORGANIZATION,
    FETCH_SCHEDULES_BY_PROJECT,
    SET_SCHEDULE_FOR_PROJECT,
    UPDATE_SCHEDULE_FOR_PROJECT,
    RESET_SCHEDULE_FOR_PROJECT,
    SAVE_SCHEDULE_FOR_PROJECT,
    DELETE_SCHEDULE_FOR_PROJECT,
    DELETE_SCHEDULE_FROM_STORE,
    RESET_STORE,
};

export default types;