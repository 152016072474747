// import { LOGIN_ACTION } from '../actions/AppCommon';
import types from './types'

const INITIAL_STATE = {
    ownReportsSummary: [],
    projectList: [],
    detailedReport: {},
    projectTasks: [],
};

const ReportsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.RESET_STORE: {
            return INITIAL_STATE;
        }
        case types.SET_OWN_REPORTS_SUMMARY: {
            let newState = {
                ...state,
                ownReportsSummary: action.value
            }
            return newState;
        }
        case types.RESET_OWN_REPORTS_SUMMARY: {
            let newState = {
                ...state,
                ownReportsSummary: []
            }
            return newState;
        }
        case types.SET_PROJECT_LIST: {
            let newState = {
                ...state,
                projectList: action.value
            }
            return newState;
        }
        case types.RESET_PROJECT_LIST: {
            let newState = {
                ...state,
                projectList: []
            }
            return newState;
        }
        case types.SET_REPORT_DETAILED: {
            let newState = {
                ...state,
                detailedReport: action.value
            }
            return newState;
        }
        case types.RESET_REPORT_DETAILED: {
            let newState = {
                ...state,
                detailedReport: {}
            }
            return newState;
        }
        case types.SET_PROJECT_TASKS: {
            let newState = {
                ...state,
                projectTasks: action.value
            }
            return newState;
        }
        case types.RESET_PROJECT_TASKS: {
            let newState = {
                ...state,
                projectTasks: []
            }
            return newState;
        }
        default:
            return state;
    };
};
export default ReportsReducer;