export const ngoRegistrationType = {
    "TRUST": "Trust",
    "SOCIETY": "Society",
    "SECTION8_COMPANY": "Section 8 Company",
}

export const projectStatusEnum = {
    "PROPOSED": "Proposed",
    "INPROGRESS": "In Progress",
    "COMPLETE": "Complete",
}

export const projectStatusEnumerator = {
    PROPOSED: {
        name: "PROPOSED",
        displayname: "Proposed",
    },
    INPROGRESS: {
        name: "INPROGRESS",
        displayname: "In Progress",
    },
    COMPLETE: {
        name: "COMPLETE",
        displayname: "Complete",
    },
}


export const taskStatusEnum = {
    "NotStarted": {
        name: "NotStarted",
        displayname: "Not Started",
    },
    "InProgress": {
        name: "InProgress",
        displayname: "In Progress",
    },
    "Complete": {
        name: "Complete",
        displayname: "Complete",
    },
}

export const invitationEnum = {
    "BID": "bidding",
    "SPONSORSHIP": "sponsorship",
    "PARTNER": "partnership",
}

export const projectInvitationStatusEnum = {
    "PENDING": {
        name: "PENDING",
        displayname: "Pending",
    },
    "PROGRESS": {
        name: "PROGRESS",
        displayname: "in-progress",
    },
    "COMPLETE": {
        name: "COMPLETE",
        displayname: "Complete",
    },
    "WITHDRAWN": {
        name: "WITHDRAWN",
        displayname: "Withdrawn",
    },
}

export const reportStatusEnum = {
    "DRAFT": {
        name: "DRAFT",
        displayTitle: "Draft",
    },
    "PUBLISHED": {
        name: "PUBLISHED",
        displayTitle: "Published",
    },
}

export const invitesPageType = {
    "INCOMING": "INCOMING",
    "OUTGOING": "OUTGOING",
}

export const inviteRequesterTypeEnum = {
    "FROM_ORGANIZATION": "FROM_ORGANIZATION",
    "TO_ORGANIZATION": "TO_ORGANIZATION",
}

export const projectInviteResponseEnum = {
    "NOACTION": {
        name: "NOACTION",
        displayTitle: "No Action",
    },
    "PENDING": {
        name: "PENDING",
        displayTitle: "Pending",
    },
    "ACCCEPTED": {
        name: "ACCCEPTED",
        displayTitle: "Accept",
    },
    "REJECTED": {
        name: "REJECTED",
        displayTitle: "Reject",
    },
    "ONHOLD": {
        name: "ONHOLD",
        displayTitle: "On Hold",
    },
    "WITHDRAW": {
        name: "WITHDRAW",
        displayTitle: "Withdraw",
    },    
}

export const projectInviteWorkflowTypeEnum = {
    "WF1_NGO_REQUEST_FOR_PRESENTATION": {
        name: "WF1_NGO_REQUEST_FOR_PRESENTATION",
        displayName: "Social Organization requests for Presentation",
    },
    "WF2_NGO_INVITE_PARTNER": {
        name: "WF2_NGO_INVITE_PARTNER",
        displayName: "Social Organization requests for partnership",
    },
    "WF3_NGO_REQUEST_TO_BID": {
        name: "WF3_NGO_REQUEST_TO_BID",
        displayName: "Social Organization requests to Bid",
    },
    "WF4_CORP_INVITE_TO_BID": {
        name: "WF4_CORP_INVITE_TO_BID",
        displayName: "Corporate invites to Bid",
    },
    "WF5_CORP_TO_CO_SPONSORSHIP": {
        name: "WF5_CORP_TO_CO_SPONSORSHIP",
        displayName: "Corporate invites to co-sponsor",
    },
    "WF6_CORP_TO_SPONSORSHIP": {
        name: "WF6_CORP_TO_SPONSORSHIP",
        displayName: "Corporate interested in sponsorship",
    },
}

export const inviteWorkflowStage1Enum = {
    "WF1_1_NGO_REQUESTS_COPR_TO_PRESENT": {
        name: "WF1_1_NGO_REQUESTS_COPR_TO_PRESENT",
        displayName: "Request requests for Sponsorship",
        nextStage: "WF1_2_CORP_ACCEPTS_INVITES_FOR_PRESENTATION"
    },
    "WF1_2_CORP_ACCEPTS_INVITES_FOR_PRESENTATION": {
        name: "WF1_2_CORP_ACCEPTS_INVITES_FOR_PRESENTATION",
        displayName: "Corporate invites for presentation",
        nextStage: "WF1_3_NGO_PRESENTS_TO_CORP"
    },
    "WF1_3_NGO_PRESENTS_TO_CORP": {
        name: "WF1_3_NGO_PRESENTS_TO_CORP",
        displayName: "NGO presents to Corporate",
        nextStage: "WF1_4_CORP_ACCEPTS_PRESENTATION_AND_SPONSORSHIP"
    },
    "WF1_4_CORP_ACCEPTS_PRESENTATION_AND_SPONSORSHIP": {
        name: "WF1_4_CORP_ACCEPTS_PRESENTATION_AND_SPONSORSHIP",
        displayName: "Corporate acccepts, offers sponsorship",
        nextStage: "WF1_5_NGO_ACCEPTS_SPONSORSHIP"
    },
    "WF1_5_NGO_ACCEPTS_SPONSORSHIP": {
        name: "WF1_5_NGO_ACCEPTS_SPONSORSHIP",
        displayName: "NGO accepts sponsorship",
        nextStage: "WF_COMPLETE"
    },
}



export const inviteWorkflowStage3Enum = {
    "WF3_1_NGO_REQUEST_CORP_TO_BID": {
        name: "WF3_1_NGO_REQUEST_CORP_TO_BID",
        displayName: "Request to bid for implementation",
        nextStage: "WF3_2_CORP_ACCETPS_INVITES_FOR_PRESENTATION"
    },
    "WF3_2_CORP_ACCETPS_INVITES_FOR_PRESENTATION": {
        name: "WF3_2_CORP_ACCETPS_INVITES_FOR_PRESENTATION",
        displayName: "Corporate invites for presentation",
        nextStage: "WF3_3_NGO_PRESENTS_CORP"
    },
    "WF3_3_NGO_PRESENTS_CORP": {
        name: "WF3_3_NGO_PRESENTS_CORP",
        displayName: "NGO presents to Corporate",
        nextStage: "WF3_4_CORP_ACCEPTS_PRESENTATION_AND_SPONSORSHIP"
    },
    "WF3_4_CORP_ACCEPTS_PRESENTATION_AND_SPONSORSHIP": {
        name: "WF3_4_CORP_ACCEPTS_PRESENTATION_AND_SPONSORSHIP",
        displayName: "Corporate acccepts, offers sponsorship",
        nextStage: "WF3_5_NGO_ACCEPTS_SPONSORSHIP"
    },
    "WF3_5_NGO_ACCEPTS_SPONSORSHIP": {
        name: "WF6_5_CORP_RESPONSE_TO_PRESENTATION",
        displayName: "NGO accepts sponsorship",
        nextStage: "WF_COMPLETE"
    },
}

export const inviteWorkflowStage4Enum = {
    "WF4_1_CORP_INVITE_NGO_TO_BID": {
        name: "WF4_1_CORP_INVITE_NGO_TO_BID",
        displayName: "Corporate invites NGO to bid",
        nextStage: "WF4_2_NGO_ACCEPTS_BID_INVITE"
    },
    "WF4_2_NGO_ACCEPTS_BID_INVITE": {
        name: "WF4_2_NGO_ACCEPTS_BID_INVITE",
        displayName: "NGO accepts invitation to bid",
        nextStage: "WF4_3_CORP_INVITES_NGO_TO_PRESENTATION"
    },
    "WF4_3_CORP_INVITES_NGO_TO_PRESENTATION": {
        name: "WF4_3_CORP_INVITES_NGO_TO_PRESENTATION",
        displayName: "Corporate invites for presentation",
        nextStage: "WF4_4_NGO_PRESENTS_PROJECT"
    },
    "WF4_4_NGO_PRESENTS_PROJECT": {
        name: "WF4_4_NGO_PRESENTS_PROJECT",
        displayName: "NGO presents to Corporate",
        nextStage: "WF4_5_CORP_RESPONSE_TO_PRESENTATION"
    },
    "WF4_5_CORP_RESPONSE_TO_PRESENTATION": {
        name: "WF4_5_CORP_RESPONSE_TO_PRESENTATION",
        displayName: "Corporate acccepts, offers sponsorship",
        nextStage: "WF4_6_NGO_ACCEPTS_SPONSORSHIP_REQUEST"
    },    
    "WF4_6_NGO_ACCEPTS_SPONSORSHIP_REQUEST": {
        name: "WF4_6_NGO_ACCEPTS_SPONSORSHIP_REQUEST",
        displayName: "NGO accepts sponsorship",
        nextStage: "WF_COMPLETE"
    },
}

export const inviteWorkflowStage6Enum = {
    "WF6_1_CORP_SHOWS_INTREST_SPONSORHIP": {
        name: "WF6_1_CORP_SHOWS_INTREST_SPONSORHIP",
        displayName: "Shows interest in the project",
        nextStage: "WF6_2_NGO_ACCEPTS_INTREST"
    },
    "WF6_2_NGO_ACCEPTS_INTREST": {
        name: "WF6_2_NGO_ACCEPTS_INTREST",
        displayName: "NGO accepts the Invite",
        nextStage: "WF6_3_CORP_INVITES_NGO_TO_PRESENTATION"
    },
    "WF6_3_CORP_INVITES_NGO_TO_PRESENTATION": {
        name: "WF6_3_CORP_INVITES_NGO_TO_PRESENTATION",
        displayName: "Invite NGO for presentation",
        nextStage: "WF6_4_NGO_PRESENTS_PROJECT"
    },
    "WF6_4_NGO_PRESENTS_PROJECT": {
        name: "WF6_4_NGO_PRESENTS_PROJECT",
        displayName: "NGO presents the project",
        nextStage: "WF6_5_CORP_RESPONSE_TO_PRESENTATION"
    },
    "WF6_5_CORP_RESPONSE_TO_PRESENTATION": {
        name: "WF6_5_CORP_RESPONSE_TO_PRESENTATION",
        displayName: "Corporate accepts the presentation",
        nextStage: "WF6_6_NGO_ACCEPTS_SPONSORSHIP_REQUEST"
    },
    "WF6_6_NGO_ACCEPTS_SPONSORSHIP_REQUEST": {
        name: "WF6_6_NGO_ACCEPTS_SPONSORSHIP_REQUEST",
        displayName: "NGO accepts sponsorship",
        nextStage: "WF_COMPLETE"
    },
}

export const ProjectEdgeType = {
    OWNER: "OWNER",
	IMPLEMENTER: "IMPLEMENTER",
	CO_IMPLEMENTER: "CO_IMPLEMENTER",
	SPONSOR: "SPONSOR",
	CO_SPONSOR: "CO_SPONSOR",
}