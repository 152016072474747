// import { LOGIN_ACTION } from '../actions/AppCommon';
import types from './types'

const INITIAL_STATE = {
    events: [],
    registeruser: {},
    projects: [],
};

const HomePageReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.SET_EVENTS:
            let newState = {
                ...state,
                events: action.value
            }
            return newState;
        case types.SET_LATEST_PROJECTS:
            let reguserState = {
                ...state,
                projects: action.value
            }
            return reguserState;

        default:
            return state;
    };
};
export default HomePageReducer;