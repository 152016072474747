import http from './http';
import config from '../common/constants/AppConstants';
import * as httpCommon from './httpCommon';
// import qs from 'qs';

export const getOwnReportsSummaryAPI = async () => {
    let apiUrl = `${config.service.serverUri}${config.service.reports.uri}`

    return new Promise((resolve, reject) => {
        http.get(apiUrl)
            .then(function (response) {
                if (httpCommon.getResponseStatus(response)) {
                    try {
                        resolve(response.data);
                    }
                    catch (error) {
                        console.log(error);
                    }
                }
                else {
                    reject(response.data);
                }
            })
            .catch(function (error) {
                reject(error.response);
            });
    });
}

export const getOwnReportDetailedAPI = async (reportid) => {
    let apiUrl = `${config.service.serverUri}${config.service.reports.uri}/${config.service.reports.report}` + `/` + reportid;

    return new Promise((resolve, reject) => {
        http.get(apiUrl)
            .then(function (response) {
                if (httpCommon.getResponseStatus(response)) {
                    try {
                        resolve(response.data);
                    }
                    catch (error) {
                        console.log(error);
                    }
                }
                else {
                    reject(response.data);
                }
            })
            .catch(function (error) {
                reject(error.response);
            });
    });
}

export const getProjectListAPI = async () => {
    let apiUrl = `${config.service.serverUri}${config.service.reports.uri}/${config.service.reports.projectlist}`

    return new Promise((resolve, reject) => {
        http.get(apiUrl, { params: { type: "OWNER", status: 'INPROGRESS' }})
            .then(function (response) {
                if (httpCommon.getResponseStatus(response)) {
                    try {
                        resolve(response.data);
                    }
                    catch (error) {
                        console.log(error);
                    }
                }
                else {
                    reject(response.data);
                }
            })
            .catch(function (error) {
                reject(error.response);
            });
    });
}

export const createReportpostAPI = async (input) => {
    let apiUrl = `${config.service.serverUri}${config.service.reports.uri}/${config.service.reports.report}`

    let payloadData = input;
    return new Promise((resolve, reject) => {
        http.post(apiUrl, payloadData)
            .then(function (response) {
                if (httpCommon.getResponseStatus(response)) {
                    try {
                        resolve(response.data);
                    }
                    catch (error) {
                        console.log(error);
                    }
                }
                else {
                    reject(response.data);
                }
            })
            .catch(function (error) {
                reject(error.response);
            });
    });
}

export const editReportpostAPI = async (input) => {
    let apiUrl = `${config.service.serverUri}${config.service.reports.uri}/${config.service.reports.report}` + `/` + input.id;

    let payloadData = input;
    return new Promise((resolve, reject) => {
        http.put(apiUrl, payloadData)
            .then(function (response) {
                if (httpCommon.getResponseStatus(response)) {
                    try {
                        resolve(response.data);
                    }
                    catch (error) {
                        console.log(error);
                    }
                }
                else {
                    reject(response.data);
                }
            })
            .catch(function (error) {
                reject(error.response);
            });
    });
}

export const deleteReportAPI = async (reportid) => {
    let apiUrl = `${config.service.serverUri}${config.service.reports.uri}/${config.service.reports.report}` + `/` + reportid;
    return new Promise((resolve, reject) => {
        http.delete(apiUrl)
            .then(function (response) {
                if (httpCommon.getResponseStatus(response)) {
                    try {
                        resolve(response.data);
                    }
                    catch (error) {
                        console.log(error);
                    }
                }
                else {
                    reject(response.data);
                }
            })
            .catch(function (error) {
                reject(error.response);
            });
    });
}

export const publishReportputAPI = async (input) => {
    let apiUrl = `${config.service.serverUri}${config.service.reports.uri}/${config.service.reports.report}` + `/` + input + `/${config.service.reports.publish}`;

    return new Promise((resolve, reject) => {
        http.put(apiUrl)
            .then(function (response) {
                if (httpCommon.getResponseStatus(response)) {
                    try {
                        resolve(response.data);
                    }
                    catch (error) {
                        console.log(error);
                    }
                }
                else {
                    reject(response.data);
                }
            })
            .catch(function (error) {
                reject(error.response);
            });
    });
}

export const unpublishReportputAPI = async (input) => {
    let apiUrl = `${config.service.serverUri}${config.service.reports.uri}/${config.service.reports.report}` + `/` + input + `/${config.service.reports.unpublish}`;

    return new Promise((resolve, reject) => {
        http.put(apiUrl)
            .then(function (response) {
                if (httpCommon.getResponseStatus(response)) {
                    try {
                        resolve(response.data);
                    }
                    catch (error) {
                        console.log(error);
                    }
                }
                else {
                    reject(response.data);
                }
            })
            .catch(function (error) {
                reject(error.response);
            });
    });
}