import React, { Component } from 'react';
import { connect } from 'react-redux';
import MyLoader from './MyLoader'

class EmptyLayout extends Component {

    render() {
        const { children } = this.props;

        return (
            <React.Fragment>
                <MyLoader active={this.props.App.isLoading}>
                    <>
                        {children}
                    </>
                </MyLoader>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        App: state.App
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
    };

}

export default connect(mapStateToProps, mapDispatchToProps)(EmptyLayout);
