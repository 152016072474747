import { put, takeLatest, call, take } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { push } from 'connected-react-router';
import types from './types';
import * as ProjectsService from '../../../services/ProjectsService'
import * as InvitationService from '../../../services/InvitationService'
import * as Actions from './Actions'

export function* getSearchProjects(action) {
    try {
        let projects = yield call(ProjectsService.SearchProjectsAPI, action.value);
        yield put(Actions.setSearchProjects(projects));

    } catch (error) {
        toast.error("Error fetching projects.", 
            { autoClose: false });
    }
}

export function* createInvite(action) {
    try {
        yield call(InvitationService.createInvitePostAPI, action.value);
        toast.success("Invite sent successfully.");

    } catch (error) {
        toast.error(error.data.error, 
            { autoClose: false });
    }
}

export const SearchOperations = [
    takeLatest(types.FETCH_PROJECTS, getSearchProjects),
    takeLatest(types.CREATE_INVITE, createInvite),
    // takeLatest(types.UPDATE_INVITE, putInviteUpdate),
]
