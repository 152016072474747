import { all } from 'redux-saga/effects';

import { LoginOperations } from './components/login/redux/LoginOperations';
import { AppCommonOperations } from './components/common/redux/AppCommonOperations';
import { ProjectOperations } from './components/projects/redux/Operations';
import { HomePageOperations } from "./components/home/redux/Operations"
import { OrganizationOperations } from "./components/OrganizationProfile/redux/Operations"
import { PublicOperations } from "./components/public/redux/Operations";
import { SearchOperations } from './components/Search/redux/Operations';
import { ReportsOperations } from './components/reports/redux/Operations';

export default function* rootSaga() {
    yield all([
        ...AppCommonOperations,
        ...LoginOperations,
        ...ProjectOperations,
        ...HomePageOperations,
        ...OrganizationOperations,
        ...PublicOperations,
        ...SearchOperations,
        ...ReportsOperations,
    ])
}