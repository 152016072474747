import axios from 'axios';
import config from '../common/constants/AppConstants'

export const GetEventsAPI = async (filter) => {
    let tokenUrl = `${config.service.serverUri}${config.service.openapi.event}`

    return new Promise((resolve, reject) => {
        axios.get(tokenUrl, { params: { eventtype: filter }})
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error.response);
            })
    });
};

export const RegisterEventUserAPI = async (eventid, registeruserdetails) => {
    let tokenUrl = `${config.service.serverUri}${config.service.openapi.event}/${eventid}`

    let payloadData = {
        "firstName": registeruserdetails.firstname,
        "lastName": registeruserdetails.lastname,
        "emailId": registeruserdetails.emailid,
        "phoneNumber1": registeruserdetails.phonenumber1,
    }

    return new Promise((resolve, reject) => {
        axios.post(tokenUrl, payloadData)
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error.response);
            })
    });
};

export const getOrganizationQueryAPI = async (regId) => {
    let tokenUrl = `${config.service.serverUri}${config.service.openapi.orgquery}`

    return new Promise((resolve, reject) => {
        axios.get(tokenUrl, { params: { regId: regId }})
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error.response);
            })
    });
};

export const getUserQueryAPI = async (mailid) => {
    let tokenUrl = `${config.service.serverUri}${config.service.openapi.userquery}`

    return new Promise((resolve, reject) => {
        axios.get(tokenUrl, { params: { email: mailid }})
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error.response);
            })
    });
};

export const getLatestProjectsAPI = async () => {
    let tokenUrl = `${config.service.serverUri}${config.service.openapi.latestprojects}`

    return new Promise((resolve, reject) => {
        axios.get(tokenUrl, { params: { limit: config.service.openapi.latestProjectLimit }})
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error.response);
            })
    });
};

