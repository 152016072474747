import axios from 'axios';
import { API_TIMEOUT } from '../common/constants/AppConstants';
import config from '../common/constants/AppConstants'
import { store } from '../store';
import Qs from 'qs'

const http = () => {
    const defaultOptions = {
        baseURL: config.service.serverUri,
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
        },
        paramsSerializer: function (params) {
            return Qs.stringify(params, {arrayFormat: 'repeat'})
        },
    };

    // Create instance
    let instance = axios.create(defaultOptions);

    // Set the AUTH token for any request
    instance.interceptors.request.use(function (config) {
        const user = store.getState().oidc.user;
        const token = user.access_token;
        config.headers.Authorization = token ? `Bearer ${token}` : '';
        return config;
    });

    return instance;
};

export default http();