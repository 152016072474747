const FETCH_ORGANIZATION_DETAILS = "FETCH_ORGANIZATION_DETAILS";
const SET_ORGANIZATION_DETAILS = "SET_ORGANIZATION_DETAILS";
const PUT_ORGANIZATION_DETAILS = "PUT_ORGANIZATION_DETAILS";
const FETCH_CORP_TYPES = "FETCH_CORP_TYPES";
const SET_CORP_TYPES = "SET_CORP_TYPES";
const FETCH_NGO_TYPES = "FETCH_NGO_TYPES";
const SET_NGO_TYPES = "SET_NGO_TYPES";
const SET_LICENSE = "SET_LICENSE";
const FETCH_NOTIFICATIONS = "FETCH_NOTIFICATIONS";
const SET_NOTIFICATIONS = "SET_NOTIFICATIONS";
const DELETE_NOTIFICATION = "DELETE_NOTIFICATION";
const MARK_NOTIFICATION_READ = "MARK_NOTIFICATION_READ";
const DELETE_NOTIFICATION_STORE = "DELETE_NOTIFICATION_STORE";
const MARK_NOTIFICATION_READ_STORE = "MARK_NOTIFICATION_READ_STORE";
const RESET_STORE = "RESET_STORE";

const types = {
    RESET_STORE,
    FETCH_ORGANIZATION_DETAILS,
    SET_ORGANIZATION_DETAILS,
    PUT_ORGANIZATION_DETAILS,
    FETCH_CORP_TYPES,
    SET_CORP_TYPES,
    FETCH_NGO_TYPES,
    SET_NGO_TYPES,
    SET_LICENSE,
    FETCH_NOTIFICATIONS,
    SET_NOTIFICATIONS,
    DELETE_NOTIFICATION,
    MARK_NOTIFICATION_READ,
    DELETE_NOTIFICATION_STORE,
    MARK_NOTIFICATION_READ_STORE,
};

export default types;